"use client"

import React, { FC, Fragment } from "react"
import Link from "next/link"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslations } from "next-intl"
import { useForm } from "react-hook-form"
import { MARK_BOLD, MARK_LINK, NODE_PARAGRAPH, render, StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import { z } from "zod"

import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/components"
import { UbCatalogueOrderFormStoryblok } from "@/components/storyblok/component-types"

interface UbCatalogueOrderFormProps {
  blok: UbCatalogueOrderFormStoryblok
}

const UbCatalogueOrderForm: FC<UbCatalogueOrderFormProps> = ({ blok }) => {
  const t = useTranslations("storefront")

  const formSchema = z.object({
    CatalogOrder_Title: z.enum(["salutation.ms", "salutation.mr", "salutation.divers"], {
      required_error: t("forms.catalogue_order_form.error_title"),
    }),
    CatalogOrder_Name: z
      .string()
      .min(2, t("forms.catalogue_order_form.error_name"))
      .max(50, t("forms.catalogue_order_form.error_max")),
    CatalogOrder_Company: z
      .string()
      .min(2, t("forms.catalogue_order_form.error_company"))
      .max(50, t("forms.catalogue_order_form.error_max")),
    CatalogOrder_Phone: z
      .string()
      .min(2, t("forms.catalogue_order_form.error_phone"))
      .max(50, t("forms.catalogue_order_form.error_max")),
    CatalogOrder_Email: z.string().email(t("forms.catalogue_order_form.error_email")),
    CatalogOrder_Street: z
      .string()
      .min(2, t("forms.catalogue_order_form.error_street"))
      .max(50, t("forms.catalogue_order_form.error_max")),
    CatalogOrder_ZIP: z
      .string()
      .regex(/^(?:\d{5}|A-\d{4}|CH-\d{4}|\d{4}\s?[A-Z]{2}|\d{4})$/, t("forms.catalogue_order_form.error_zip")),
    CatalogOrder_City: z
      .string()
      .min(2, t("forms.catalogue_order_form.error_city"))
      .max(50, t("forms.catalogue_order_form.error_max")),
    CatalogOrder_Country: z
      .string()
      .min(2, t("forms.catalogue_order_form.error_country"))
      .max(50, t("forms.catalogue_order_form.error_max")),
    acceptTerms: z.literal<boolean>(true, {
      errorMap: () => ({ message: t("forms.catalogue_order_form.error_terms") }),
    }),
  })

  // form definition
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      CatalogOrder_Title: undefined,
      CatalogOrder_Name: "",
      CatalogOrder_Company: "",
      CatalogOrder_Phone: "",
      CatalogOrder_Email: "",
      CatalogOrder_Street: "",
      CatalogOrder_ZIP: "",
      CatalogOrder_City: "",
      CatalogOrder_Country: "",
      acceptTerms: false,
    },
  })

  // submit handler
  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values)
  }

  const renderedRichText = (richtext: StoryblokRichtext | unknown) => {
    return render(richtext, {
      markResolvers: {
        [MARK_BOLD]: (children) => <span className={"font-bold"}>{children}</span>,
        [MARK_LINK]: (children, { linktype, href, target, uuid, custom }) => {
          if (linktype === "story" && href) {
            return (
              <Link prefetch={false} className={"text-primary"} href={href} target={target}>
                {children}
              </Link>
            )
          }
          if (linktype === "url" && href) {
            return (
              <a
                className={"text-primary"}
                href={href}
                target={target}
                rel={target === "_blank" ? "noopener noreferrer" : undefined}
              >
                {children}
              </a>
            )
          }
          return (
            <a className={"text-primary"} href={"#"}>
              {children}
            </a>
          )
        },
      },
      nodeResolvers: {
        [NODE_PARAGRAPH]: (children) => {
          return <Fragment>{!children ? <br /> : <>{children}</>}</Fragment>
        },
      },
    })
  }

  return (
    <>
      {blok.title && (
        <Heading element={"h2"} marginBottom={"6"}>
          {blok.title}
        </Heading>
      )}
      <div className={"gap-6 lg:flex"}>
        <p className={"lg:w-1/4"}>{blok.description}</p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={"lg:w-3/4"}>
            <fieldset className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-12">
              <FormField
                control={form.control}
                name="CatalogOrder_Title"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-4"}>
                    <FormLabel className={"font-bold"}>{t("forms.catalogue_order_form.label_title")}</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t("forms.catalogue_order_form.select_title")} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="salutation.ms">Frau</SelectItem>
                        <SelectItem value="salutation.mr">Herr</SelectItem>
                        <SelectItem value="salutation.divers">Divers</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="CatalogOrder_Name"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-4"}>
                    <FormLabel className={"font-bold"}>{t("forms.catalogue_order_form.label_name")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="CatalogOrder_Company"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-4"}>
                    <FormLabel className={"font-bold"}>{t("forms.catalogue_order_form.label_company")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="CatalogOrder_Phone"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-6"}>
                    <FormLabel className={"font-bold"}>{t("forms.catalogue_order_form.label_phone")}</FormLabel>
                    <FormControl>
                      <Input type={"tel"} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="CatalogOrder_Email"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-6"}>
                    <FormLabel className={"font-bold"}>{t("forms.catalogue_order_form.label_email")}</FormLabel>
                    <FormControl>
                      <Input type={"email"} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="CatalogOrder_Street"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-3"}>
                    <FormLabel className={"font-bold"}>{t("forms.catalogue_order_form.label_street")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="CatalogOrder_ZIP"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-3"}>
                    <FormLabel className={"font-bold"}>{t("forms.catalogue_order_form.label_zip")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="CatalogOrder_City"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-3"}>
                    <FormLabel className={"font-bold"}>{t("forms.catalogue_order_form.label_city")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="CatalogOrder_Country"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-3"}>
                    <FormLabel className={"font-bold"}>{t("forms.catalogue_order_form.label_country")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="acceptTerms"
                render={({ field }) => (
                  <FormItem className={"lg:col-span-12"}>
                    <FormControl>
                      <Checkbox className={"mr-2"} checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <FormLabel className={"text-base text-black"}>
                      {renderedRichText(blok.legalconsent_notification)}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </fieldset>
            <Button type="submit" size={"sm"} className={"mb-6"}>
              {t("forms.catalogue_order_form.send")}
            </Button>
            <p className={"text-sm"}>{renderedRichText(blok.captcha_hint)}</p>
          </form>
        </Form>
      </div>
    </>
  )
}

export default UbCatalogueOrderForm
