// To be used by both, server and by client components!

import { NEXT_HEADER_SB_LOCALE, NEXT_HEADER_URL_KEY } from "@/constants"

import { getSbLocaleFromHostAndUri } from "../locale-helper"
import { logger } from "../logger"
import { Locales } from "./constants.cjs"

export function getSbLocale(): string {
  const runsOnServerSide = typeof window === "undefined"
  if (runsOnServerSide) {
    const headers = require("next/headers").headers
    const sbLocaleFromHeader = headers().get(NEXT_HEADER_SB_LOCALE)
    const urlFromHeader = headers().get(NEXT_HEADER_URL_KEY)

    let sbLocale: string
    // note: sbLocaleFromHeader may correctly be empty ("")
    if (sbLocaleFromHeader == null) {
      logger.error(
        `getSbLocaleOnServer(): Internal error: locale header ${NEXT_HEADER_SB_LOCALE} is not set during request ${urlFromHeader}.`
      )
      console.trace()
      sbLocale = Locales.defaultSbLocale
    } else {
      sbLocale = sbLocaleFromHeader
    }
    // logger.debug(`getSbLocale on server returns ${sbLocale}.`)
    return sbLocale
  } else {
    const sbLocale = getSbLocaleFromHostAndUri(window.location.host, window.location.pathname)
    logger.debug(`getSbLocale on client returns ${sbLocale}.`)
    return sbLocale
  }
}

// returns e.g. "de-AT", "nl-NL"
export function getIsoLocale() {
  return getIsoLocaleFromSbLocale(getSbLocale())
}

// return e.g. "de-at", "nl"
export function getShortLowerIsoLocale() {
  if (getCountry() == getLanguage()) {
    return getCountry()
  }
  return getIsoLocale().toLowerCase()
}

// returns e.g. "de_DE" (instead of de_AT - this is because of the intershop site config)
export function getIntershopLocale(): string {
  return getIntershopLocaleFromSbLocale(getSbLocale())
}

export function getIntershopLocaleFromSbLocale(sbLocale?: string): string {
  if (sbLocale === undefined) {
    sbLocale = getSbLocale()
  }
  return Locales.sbLocaleToIntershopLocale[sbLocale]
}

// returns e.g. "de-AT"
export function getIsoLocaleFromSbLocale(sbLocale: string): string {
  const isoLocale = Locales.sbLocaleToIsoLocale[sbLocale]
  if (!isoLocale) {
    logger.warn(`getIsoLocaleFromSbLocale(): Internal error: no isoLocale found for sbLocale ${sbLocale}.`)
    return Locales.sbLocaleToIsoLocale[Locales.defaultSbLocale]
  }
  return isoLocale
}

// returns e.g. "at" (lower case)
export function getCountry(): string {
  const isoLocale = getIsoLocale() // e.g. "de-AT"
  const country = isoLocale.split("-")[1] // will always have at least 2 parts
  return country.toLowerCase()
}

// returns e.g. "de" for "de-CH" (lower case)
export function getLanguageFromSbLocale(sbLocale: string): string {
  const isoLocale = getIsoLocaleFromSbLocale(sbLocale)
  const language = isoLocale.split("-")[0] // will always have at least 2 parts
  return language.toLowerCase()
}

// returns e.g. "de" for "de-CH" (lower case)
export function getLanguage(): string {
  const sbLocale = getSbLocale() // e.g. "de-AT"
  return getLanguageFromSbLocale(sbLocale)
}

// returns "EUR" or "CHF" (in ch)
export function getCurrency(): string {
  return getCountry() === "ch" ? "CHF" : "EUR"
}
