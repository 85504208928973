// colors: see https://gist.github.com/abritinthebay/d80eb99b2726c83feb0d97eab95206c4
const reset = "\x1b[0m"
const green = "\x1b[32m"
const yellow = "\x1b[33m"
const blue = "\x1b[34m"
const red = "\x1b[31m"
const cyan = "\x1b[36m"
const bright = "\x1b[1m"

type LogLevel = "trace" | "debug" | "info" | "warn" | "error"

type ColorMap = {
  [key in LogLevel]: string
}

const colors: ColorMap = {
  trace: cyan,
  debug: blue,
  info: bright,
  warn: yellow,
  error: red,
}

class SimpleLogger {
  private currentMaxLogLevel: LogLevel
  private levels: LogLevel[] = ["trace", "debug", "info", "warn", "error"]

  constructor() {
    this.currentMaxLogLevel = this.getLogLevelFromEnv()
    //console.log("Log level is " + this.currentMaxLogLevel)
  }

  private getLogLevelFromEnv(): LogLevel {
    const logLevel = process.env.NEXT_LOG_LEVEL || "info"
    if (["trace", "debug", "info", "warn", "error"].includes(logLevel)) {
      return logLevel as LogLevel
    }
    return "info"
  }

  private shouldLog(level: LogLevel): boolean {
    const currentLevelIndex = this.levels.indexOf(this.currentMaxLogLevel)
    const messageLevelIndex = this.levels.indexOf(level)
    return messageLevelIndex >= currentLevelIndex
  }

  private log(level: LogLevel, message: string): void {
    if (this.shouldLog(level)) {
      const color = colors[level]
      console.log(color + this.formatMessage(level.toUpperCase(), message) + reset)
    }
  }

  trace(message: string): void {
    this.log("trace", message)
  }

  debug(message: string): void {
    this.log("debug", message)
  }

  info(message: string): void {
    this.log("info", message)
  }

  warn(message: string): void {
    this.log("warn", message)
  }

  error(message: string): void {
    this.log("error", message)
  }

  logObject(level: LogLevel, message: string, obj: any, options?: any): void {
    if (this.shouldLog(level)) {
      this.log(level, message)
      console.dir(obj, options)
    }
  }

  private formatMessage(level: string, message: string): string {
    const timestamp = new Date().toISOString()
    return `${timestamp} - ${level}: ${message}`
  }
}

export const logger = new SimpleLogger()
