"use client"

import Link from "next/link"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { formatAndReplacePrices } from "@/lib/helper"
import { getLinkWithEncryptedFfSearchParams } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"

interface FacetElementProps {
  facetElementData: FacetElementData
  query?: string
  baseCategory?: Category
  brandSlug?: string
}

function FacetElement(props: FacetElementProps) {
  const hitcount = props.facetElementData.hitcount
  const elementname = props.facetElementData.elementname
  const selected = props.facetElementData.selected

  const { setIsLoading } = useUbStorefrontState()

  const link = getLinkWithEncryptedFfSearchParams(
    props.query,
    props.baseCategory,
    props.brandSlug,
    props.facetElementData.encryptedFfSearchParams
  )

  return (
    <Link prefetch={false} href={link} onClick={() => setIsLoading(true)}>
      <div className={"flex justify-between"}>
        <div className="flex min-w-40 flex-row items-center gap-1 transition duration-200 ease-in-out hover:translate-x-1 hover:text-primary">
          <input
            className={"checked:bg-primary hover:checked:bg-primary focus:ring-0"}
            name={"filter_" + elementname}
            type="checkbox"
            defaultChecked={selected}
          />

          <label htmlFor={"filter_" + elementname}>{formatAndReplacePrices(elementname)}</label>
        </div>
        <p className={"rounded-sm border border-gray-300 px-[0.2rem] text-[0.6rem] font-bold text-gray-400"}>
          {hitcount}
        </p>
      </div>
    </Link>
  )
}

export default FacetElement
