import { cva, type VariantProps } from "class-variance-authority"

import { toClassVariant } from "../helpers/utils"

const textAlignValues = ["left", "center", "right", "justify", "start", "end"]

const textAlignDef = cva("", {
  variants: {
    textAlign: toClassVariant(textAlignValues, "text"),
  },
})

export { textAlignValues, textAlignDef }
export type TextAlignProp = VariantProps<typeof textAlignDef>
