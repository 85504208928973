"use client"

import React, { PropsWithChildren } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import { getLanguage } from "@/lib/link-utils/get-sb-locale"

interface GoogleReCaptchaWrapperProps extends PropsWithChildren {
  domainKey: string
}

const GoogleReCaptchaWrapper = (props: GoogleReCaptchaWrapperProps) => {
  const language = getLanguage()

  return (
    <>
      <style jsx global>{`
        .grecaptcha-badge {
          bottom: 51px !important;
        }
      `}</style>
      <GoogleReCaptchaProvider
        reCaptchaKey={props.domainKey ?? "NOT DEFINED"}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined,
        }}
        language={language}
      >
        {props.children}
      </GoogleReCaptchaProvider>
    </>
  )
}

export default GoogleReCaptchaWrapper
