import {
  DEFAULT_SEARCH_RESULT_PAGE_SIZES,
  DEFAULT_SEARCH_RESULT_SORT_ORDER,
  SEARCH_PARAMS_PAGE,
  SEARCH_PARAMS_PAGE_SIZE,
  SEARCH_PARAMS_SORT_ORDER,
} from "@/constants/ui"

import { SortOrder } from "@/lib/factfinder/factfinder-backend"

export interface PaginationInfo {
  start: number
  end: number
}

export const getPaginationInfo = (
  interval: number,
  page: number,
  lastPage: number,
  visibleCount: number
): PaginationInfo[] => {
  const junksCount = Math.ceil(lastPage / interval)
  const junks = Array.from({ length: junksCount }, (_v, junk) => {
    const rest = Math.ceil(lastPage - junk * interval)
    const size = rest >= interval ? interval : rest
    return Array.from({ length: size }, (_v, i) => i + junk * interval + 1)
  })
  const junkIndexForPage = junks.findIndex((junk) => junk.includes(page))

  const previousJunks = junks.slice(0, junkIndexForPage).map((junk) => {
    return {
      start: junk[0],
      end: junk.slice(-1)[0],
    } as PaginationInfo
  })
  const currentJunk = junks[junkIndexForPage].map((junk) => {
    return {
      start: junk,
      end: junk,
    } as PaginationInfo
  })
  const nextJunks = junks.slice(junkIndexForPage + 1).map((junk) => {
    return {
      start: junk[0],
      end: junk.slice(-1)[0],
    } as PaginationInfo
  })

  const remainingCount = visibleCount - currentJunk.length
  const fullResultList = [...previousJunks, ...currentJunk, ...nextJunks]
  const indexOfCurrent = fullResultList.findIndex((result) => result.start === currentJunk[0].start)
  const countPrev =
    nextJunks.length >= Math.floor(remainingCount / 2)
      ? Math.min(indexOfCurrent, Math.floor(remainingCount / 2))
      : Math.min(remainingCount - nextJunks.length, previousJunks.length)
  const countNext = Math.min(visibleCount - countPrev - currentJunk.length, nextJunks.length)

  return [...previousJunks.slice(-countPrev), ...currentJunk, ...nextJunks.slice(0, countNext)] as PaginationInfo[]
}

export interface PaginationSerachParams {
  page: number
  pageSize: number
  sortOrder: SortOrder
}

export const getPaginationSearchParams = (searchParams?: URLSearchParams): PaginationSerachParams => {
  const page = Number(searchParams?.get(SEARCH_PARAMS_PAGE) ?? 1)
  const pageSize = Number(searchParams?.get(SEARCH_PARAMS_PAGE_SIZE) ?? DEFAULT_SEARCH_RESULT_PAGE_SIZES[0])
  const sortOrder: SortOrder = (searchParams?.get(SEARCH_PARAMS_SORT_ORDER) ??
    DEFAULT_SEARCH_RESULT_SORT_ORDER[0]) as SortOrder

  return {
    page: page,
    pageSize: pageSize,
    sortOrder: sortOrder,
  }
}
