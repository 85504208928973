export const UB_EXIT_POPUP_COOKIE_NAME = "ecShown" // must match legacy site

export namespace ExitPopupCookieValue {
  export function isEcShown(cookieVal: string | undefined): boolean {
    if (!cookieVal) {
      return false
    } else {
      return "true" === cookieVal
    }
  }
}
