"use client"

import React, { useRef, useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslations } from "next-intl"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"

import { getRenderedRichText } from "@/components/ui/helpers/richtextRenderer"
import { cn } from "@/components/ui/helpers/utils"
import StoryblokAssetClient from "@/components/storyblok/common/StoryblokAssetClient"
import { AssetStoryblok, RichtextStoryblok } from "@/components/storyblok/component-types"

interface UbNewsletterSubscriptionFormClientProps {
  inxmaiSubscriptionServletUrl: string
  inxmailSubscription: string
  inxmailHttpRedirect: string
  inxmailHttpRedirectError: string
  inxmailCharset: string
  icon?: AssetStoryblok
  title?: RichtextStoryblok
  backgroundImage?: AssetStoryblok
  gdprText?: RichtextStoryblok
  footnote?: RichtextStoryblok
}

const UbNewsletterSubscriptionFormClient = (props: UbNewsletterSubscriptionFormClientProps) => {
  const formRef = useRef<HTMLFormElement>(null)
  const t = useTranslations("storefront")
  const [sending, setSending] = useState(false)

  const salutationValues = () => {
    return [t("salutation.ms"), t("salutation.mr"), t("salutation.divers"), ""] as const
  }

  const validationSchema = z.object({
    INXMAIL_SUBSCRIPTION: z.string().min(1),
    INXMAIL_HTTP_REDIRECT: z.string().min(1),
    INXMAIL_HTTP_REDIRECT_ERROR: z.string().min(1),
    INXMAIL_CHARSET: z.string().min(1),
    vorname: z.string(),
    nachname: z.string(),
    anrede: z.enum(salutationValues()).transform((value) => value ?? ""),
    email: z.string().email(),
    INXMAIL_TRACKINGPERMISSION: z
      .boolean()
      .nullish()
      .transform((value) => (value ? "on" : undefined)),
  })

  type ValidationSchemaType = z.infer<typeof validationSchema>
  const { register, handleSubmit } = useForm<ValidationSchemaType>({ resolver: zodResolver(validationSchema) })

  const onSubmit: SubmitHandler<ValidationSchemaType> = () => {
    setSending(true)
    formRef.current?.submit()
  }

  return (
    <>
      <div className="w-full">
        <div
          style={{ backgroundImage: `url(${props.backgroundImage?.filename})` }}
          className="mb-[3.75rem] h-auto min-h-[2.625rem] bg-cover"
        >
          <div className="mb-[-2.625rem] flex h-full justify-center px-5 pt-[6.938rem] sm:px-[2.813rem] xl:px-[13.75rem]">
            <div
              className={cn("relative mx-auto mb-[-3.75rem] min-h-[32rem] max-w-[41.875rem] bg-white p-10 shadow-md", {
                "pointer-events-none opacity-50 cursor-wait": sending,
              })}
            >
              {props.icon && (
                <div className="absolute left-1/2 top-0 w-[6.25rem] -translate-x-1/2 -translate-y-1/2">
                  {props.icon && <StoryblokAssetClient className="w-full" asset={props.icon} />}
                </div>
              )}
              <div className="mb-2.5 mt-5 prose-headings:text-center prose-headings:text-2xl prose-headings:font-normal">
                {props.title?.content?.[0].content && getRenderedRichText(props.title)}
              </div>
              <form
                className="grid grid-cols-1 gap-x-10 gap-y-5 xl:grid-cols-2"
                ref={formRef}
                action={props.inxmaiSubscriptionServletUrl}
                method="post"
                onSubmit={handleSubmit(onSubmit)}
              >
                <input type="hidden" {...register("INXMAIL_SUBSCRIPTION")} value={props.inxmailSubscription} />
                <input type="hidden" {...register("INXMAIL_HTTP_REDIRECT")} value={props.inxmailHttpRedirect} />
                <input
                  type="hidden"
                  {...register("INXMAIL_HTTP_REDIRECT_ERROR")}
                  value={props.inxmailHttpRedirectError}
                />
                <input type="hidden" {...register("INXMAIL_CHARSET")} value={props.inxmailCharset} />
                <div className="flex flex-col">
                  <label className="mb-[0.313rem] text-lg font-bold" htmlFor="firstName">
                    {t("forms.newsletter_subscription_form.placeholder.first_name")}
                  </label>
                  <input className="h-10 focus:border-primary focus:ring-0" {...register("vorname")} type="text" />
                </div>
                <div className="flex flex-col">
                  <label className="mb-[0.313rem] text-lg font-bold" htmlFor="lastName">
                    {t("forms.newsletter_subscription_form.placeholder.last_name")}
                  </label>
                  <input className="h-10 focus:border-primary focus:ring-0" {...register("nachname")} type="text" />
                </div>
                <div className="xl:col-span-2">
                  <select
                    className="h-10 w-full focus:border-primary focus:ring-0"
                    {...register("anrede")}
                    defaultValue=""
                  >
                    <option value="" disabled hidden>
                      {t("forms.newsletter_subscription_form.placeholder.salutation")}
                    </option>
                    <option value={t("salutation.ms")}>{t("salutation.ms")}</option>
                    <option value={t("salutation.mr")}>{t("salutation.mr")}</option>
                    <option value={t("salutation.divers")}>{t("salutation.divers")}</option>
                  </select>
                </div>
                <div className="flex flex-col xl:col-span-2">
                  <label className="mb-[0.313rem] text-lg font-bold" htmlFor="emailAddress">
                    {t("forms.newsletter_subscription_form.placeholder.email") + "**"}
                  </label>
                  <input
                    className="h-10 focus:border-primary focus:ring-0"
                    {...register("email")}
                    type="email"
                    required={true}
                  />
                </div>
                {props.gdprText?.content?.[0].content && (
                  <div className="flex flex-row gap-x-2 xl:col-span-2">
                    <input
                      id="gdprConfirmation"
                      className="size-[1.125rem] checked:text-primary hover:ring-0 focus:border-primary focus:ring-0"
                      {...register("INXMAIL_TRACKINGPERMISSION")}
                      type="checkbox"
                    />
                    <label className="prose-p:text-sm" htmlFor="gdprConfirmation">
                      {getRenderedRichText(props.gdprText)}
                    </label>
                  </div>
                )}
                <button
                  className="w-full bg-primary px-[0.938rem] py-[0.563rem] text-lg text-white xl:col-span-2"
                  type="submit"
                  disabled={sending}
                >
                  {t("forms.newsletter_subscription_form.placeholder.submit")}
                </button>
              </form>
            </div>
          </div>
        </div>
        {props.footnote?.content?.[0].content && (
          <div className="pt-[4.375rem] prose-p:text-sm">{getRenderedRichText(props.footnote)}</div>
        )}
      </div>
    </>
  )
}

export default UbNewsletterSubscriptionFormClient
