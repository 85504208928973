import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toClassVariant(keys: string[], def: string): Record<string, string> {
  const defObject: Record<string, string> = {}
  keys.forEach((k) => {
    defObject[k] = `${def}-${k}`
  })
  return defObject
}

type Styles = {
  [key: string]: string
}

export function bind(styles: Styles) {
  return function (...args: (string | string[] | { [key: string]: boolean } | undefined)[]): string {
    const classes: string[] = []

    args.forEach((arg) => {
      if (typeof arg === "string" && styles[arg]) {
        classes.push(styles[arg])
      } else if (Array.isArray(arg)) {
        arg.forEach((item) => {
          if (styles[item]) {
            classes.push(styles[item])
          }
        })
      } else if (typeof arg === "object" && arg !== null) {
        Object.keys(arg).forEach((key) => {
          if (arg[key] && styles[key]) {
            classes.push(styles[key])
          }
        })
      }
    })

    return classes.join(" ")
  }
}
