import { icons, LucideProps } from "lucide-react"

// workaround for delayed icon loading + slow turnaround times:
// https://github.com/lucide-icons/lucide/issues/1576#issuecomment-1939235923

type IconName = keyof typeof icons

interface IconProps extends LucideProps {
  name: IconName
}

const Icon = ({ name, ...props }: IconProps) => {
  const DynLucideIcon = icons[name]

  return <DynLucideIcon {...props} />
}

export default function LucideIcon({ blok }) {
  if (!blok?.icon) {
    return null
  }

  const iconProps: IconProps = {
    name: getEffectiveIconName(blok?.icon),
    width: 24,
    height: 24,
  }

  return <Icon {...iconProps} />
}

function toCamelCase(value: string): string {
  return value
    .toLowerCase()
    .replace(/-./g, (match) => match.charAt(1).toUpperCase())
    .replace(/^./, (match) => match.toUpperCase())
}

function getEffectiveIconName(nameFromSb: string): IconName {
  if (nameFromSb in icons) {
    return nameFromSb as IconName
  }

  const camelCaseName = toCamelCase(nameFromSb)
  if (camelCaseName in icons) {
    return camelCaseName as IconName
  }

  return "Target"
}
