"use client"

import { useEffect } from "react"
import { usePathname, useSearchParams } from "next/navigation"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

export default function Loading() {
  const { isLoading, setIsLoading } = useUbStorefrontState()

  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    //const url = `${pathname}?${searchParams}`
    //console.log(url)
    setIsLoading(false)
  }, [pathname, searchParams, setIsLoading])

  return (
    <div className="pointer-events-none fixed z-[9999] size-full">
      {isLoading && (
        <>
          <div className="pointer-events-auto fixed size-full bg-black opacity-50"></div>
          <div className="pointer-events-auto flex size-full items-center justify-center">
            <div className="loading absolute opacity-100"></div>
          </div>
        </>
      )}
    </div>
  )
}
