import React, { AnchorHTMLAttributes } from "react"
import Link from "next/link"

import { MultilinkStoryblok } from "@/components/storyblok/component-types"

// see: https://www.storyblok.com/docs/guide/in-depth/rendering-the-link-field

interface StoryblokMultilinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href" | "target" | "rel"> {
  link?: MultilinkStoryblok
  sbLocale?: string
  label?: string
}

const StoryblokMultilink = ({
  link,
  children,
  sbLocale,
  label,
  ...restProps
}: StoryblokMultilinkProps): JSX.Element => {
  if (link && link?.linktype === "story" && link.cached_url) {
    // Todo: check if okay - for now its just here to make it build WITHOUT translatable slugs
    return (
      <Link prefetch={false} {...restProps} href={link.cached_url} target={link.target} aria-label={label}>
        {children}
      </Link>
    )
  }

  if (link?.linktype === "url" && link.url) {
    return (
      <a
        aria-label={label}
        href={link.url}
        {...restProps}
        target={link.target}
        rel={link.target === "_blank" ? "noopener noreferrer" : undefined}
      >
        {children}
      </a>
    )
  }

  /**
   * @todo The link to the asset should not be executed. Instead, the asset is displayed in a lightbox as an overlay.
   */
  if (link?.linktype === "asset" && link.url) {
    return (
      <a aria-label={label} href={link.url} {...restProps} target={link.target}>
        {children}
      </a>
    )
  }

  if (link?.linktype === "email" && link.email) {
    return (
      <a aria-label={label} href={`mailto:${link.email}`} {...restProps} target={link.target}>
        {children}
      </a>
    )
  }

  // If link type is unknown or missing, return children without a link
  return (
    <a aria-label={label} href={"#"} {...restProps}>
      {children}
    </a>
  )
}

export default StoryblokMultilink
