import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

// See: https://www.youtube.com/watch?v=re2JFITR7TI
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
/*

// colors: see https://gist.github.com/abritinthebay/d80eb99b2726c83feb0d97eab95206c4
export const reset = "\x1b[0m"
export const green = "\x1b[32m"
export const yellow = "\x1b[33m"
export const blue = "\x1b[34m"
export const red = "\x1b[31m"

export function mylog(prefix_color: string, message_color: string, message: string) {
  console.log(`${prefix_color}%s: ${message_color}%s${reset}`, `${new Date()}`, message)
}
*/
