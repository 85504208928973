import { cva, type VariantProps } from "class-variance-authority"

import { toClassVariant } from "../helpers/utils"

const paddingValues = [
  "0",
  "0.5",
  "1",
  "1.5",
  "2",
  "2.5",
  "3",
  "3.5",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "14",
  "16",
  "20",
  "24",
  "28",
  "32",
  "36",
  "40",
]

const paddingDef = cva("", {
  variants: {
    padding: toClassVariant(paddingValues, "p"),
    paddingRight: toClassVariant(paddingValues, "pr"),
    paddingBottom: toClassVariant(paddingValues, "pb"),
    paddingLeft: toClassVariant(paddingValues, "pl"),
    paddingTop: toClassVariant(paddingValues, "pt"),
    paddingX: toClassVariant(paddingValues, "px"),
    paddingY: toClassVariant(paddingValues, "py"),
    // You can use variant modifiers to target media queries such as responsive breakpoint lg
    paddingLg: toClassVariant(paddingValues, "lg:p"),
    paddingRightLg: toClassVariant(paddingValues, "lg:pr"),
    paddingBottomLg: toClassVariant(paddingValues, "lg:pb"),
    paddingLeftLg: toClassVariant(paddingValues, "lg:pl"),
    paddingTopLg: toClassVariant(paddingValues, "lg:pt"),
    paddingXLg: toClassVariant(paddingValues, "lg:px"),
    paddingYLg: toClassVariant(paddingValues, "lg:py"),
  },
})

export { paddingDef, paddingValues }
export type PaddingProps = VariantProps<typeof paddingDef>
