import React, { type HTMLAttributes } from "react"
import { Slot } from "@radix-ui/react-slot"

import { cn } from "../helpers/utils"
import {
  marginDef,
  textAlignDef,
  textColorDef,
  type MarginProps,
  type TextAlignProp,
  type TextColorProps,
} from "../props"

type TypographyElement = React.ElementRef<"p">

interface TypographyProps extends TextColorProps, TextAlignProp, MarginProps, HTMLAttributes<HTMLParagraphElement> {
  element?: "p" | "span" | "div"
  asChild?: boolean
}

const Typography = React.forwardRef<TypographyElement, TypographyProps>(
  (
    {
      children,
      element,
      marginTop,
      marginBottom,
      marginTopLg,
      marginBottomLg,
      textColor,
      textAlign,
      className,
      asChild = false,
      ...restProps
    },
    forwardedRef
  ) => {
    const Element = element || "p"

    return (
      <Slot
        {...restProps}
        ref={forwardedRef}
        className={cn(
          textColorDef({ textColor }),
          textAlignDef({ textAlign }),
          marginDef({
            marginTop,
            marginBottom,
            marginTopLg,
            marginBottomLg,
          }),
          "3xl:text-2xl",
          className
        )}
      >
        {asChild ? children : <Element>{children}</Element>}
      </Slot>
    )
  }
)
Typography.displayName = "Typography"

export { Typography }
export type { TypographyProps }
