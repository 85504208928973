import { formatPrice } from "@/lib/helper"

export interface ScalePrice {
  gross: number
  net: number
  minQuantity: number
}

export interface CardPrices {
  listPrice: number
  salePrice: number
  discountPercent: number
  listPriceFormatted: string
  salePriceFormatted: string
  discountPercentFormatted: string
  isSinglePrice?: boolean
  scalePrices?: ScalePrice[]
}

export function determineCardPrices(isB2BCustomer: boolean, prices): CardPrices {
  if (prices?.data?.[0]?.prices?.MinListPrice?.[0]) {
    return determineCardPricesForVariants(isB2BCustomer, prices)
  } else {
    return determineCardPricesForSingleArticle(isB2BCustomer, prices)
  }
}

function determineCardPricesForSingleArticle(isB2BCustomer: boolean, prices) {
  const listPrice: number = isB2BCustomer
    ? prices?.data?.[0]?.prices?.ListPrice?.[0]?.net.value
    : prices?.data?.[0]?.prices?.ListPrice?.[0]?.gross.value

  const salePrice: number = isB2BCustomer
    ? prices?.data?.[0]?.prices?.SalePrice?.[0]?.net.value
    : prices?.data?.[0]?.prices?.SalePrice?.[0]?.gross.value

  const listPriceFormatted = isB2BCustomer
    ? formatPrice(
        prices?.data?.[0]?.prices?.ListPrice?.[0]?.net.value,
        prices?.data?.[0]?.prices?.ListPrice?.[0]?.net.currency
      )
    : formatPrice(
        prices?.data?.[0]?.prices?.ListPrice?.[0]?.gross.value,
        prices?.data?.[0]?.prices?.ListPrice?.[0]?.gross.currency
      )

  const salePriceFormatted = isB2BCustomer
    ? formatPrice(
        prices?.data?.[0]?.prices?.SalePrice?.[0]?.net.value,
        prices?.data?.[0]?.prices?.SalePrice?.[0]?.net.currency
      )
    : formatPrice(
        prices?.data?.[0]?.prices?.SalePrice?.[0]?.gross.value,
        prices?.data?.[0]?.prices?.SalePrice?.[0]?.gross.currency
      )

  const discountPercent: number = salePrice > 0 && listPrice > 0 ? Math.round(100 - (salePrice / listPrice) * 100) : 0

  const discountPercentFormatted = discountPercent + "%"

  const scalePrices: ScalePrice[] = []

  if (prices?.data?.[0]?.prices?.SalePrice?.length > 1) {
    for (const scaledPrice of prices?.data?.[0]?.prices?.SalePrice) {
      const scalePrice: ScalePrice = {
        gross: scaledPrice.gross.value,
        net: scaledPrice.net?.value,
        minQuantity: scaledPrice.minQuantity.value,
      }
      scalePrices.push(scalePrice)
    }
  }

  return {
    listPrice: listPrice,
    salePrice: salePrice,
    discountPercent: discountPercent,
    listPriceFormatted: listPriceFormatted,
    salePriceFormatted: salePriceFormatted,
    discountPercentFormatted: discountPercentFormatted,
    isSinglePrice: true,
    scalePrices: scalePrices,
  }
}

export function determineCardPricesForVariants(isB2BCustomer: boolean, prices): CardPrices {
  const listPrice = isB2BCustomer
    ? prices?.data?.[0]?.prices?.MinListPrice?.[0]?.net.value
    : prices?.data?.[0]?.prices?.MinListPrice?.[0]?.gross.value

  const salePrice = isB2BCustomer
    ? prices?.data?.[0]?.prices?.MinSalePrice?.[0]?.net.value
    : prices?.data?.[0]?.prices?.MinSalePrice?.[0]?.gross.value

  const listPriceFormatted = isB2BCustomer
    ? formatPrice(
        prices?.data?.[0]?.prices?.MinListPrice?.[0]?.net.value,
        prices?.data?.[0]?.prices?.MinListPrice?.[0]?.net.currency
      )
    : formatPrice(
        prices?.data?.[0]?.prices?.MinListPrice?.[0]?.gross.value,
        prices?.data?.[0]?.prices?.MinListPrice?.[0]?.gross.currency
      )

  const salePriceFormatted = isB2BCustomer
    ? formatPrice(
        prices?.data?.[0]?.prices?.MinSalePrice?.[0]?.net.value,
        prices?.data?.[0]?.prices?.MinSalePrice?.[0]?.net.currency
      )
    : formatPrice(
        prices?.data?.[0]?.prices?.MinSalePrice?.[0]?.gross.value,
        prices?.data?.[0]?.prices?.MinSalePrice?.[0]?.gross.currency
      )

  const discountPercent = salePrice > 0 && listPrice > 0 ? Math.round(100 - (salePrice / listPrice) * 100) : 0

  const discountPercentFormatted = discountPercent + "%"

  return {
    listPrice: listPrice,
    salePrice: salePrice,
    discountPercent: discountPercent,
    listPriceFormatted: listPriceFormatted,
    salePriceFormatted: salePriceFormatted,
    discountPercentFormatted: discountPercentFormatted,
    isSinglePrice: false,
  }
}
