"use client"

import { createContext, Dispatch, FC, MouseEvent, SetStateAction, useContext, useEffect, useRef, useState } from "react"
import Image from "next/image"
import { storyblokEditable } from "@storyblok/react/rsc"
import { ImageRO } from "@ub/product-client"

import { ReducedProductData } from "@/lib/storefront/product/product"
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/components"
import { UbProductImageStoryblok } from "@/components/storyblok/component-types"

interface UBProductImageGalleryProps {
  blok: UbProductImageStoryblok
  product: ReducedProductData
}

interface GalleryImageProps {
  id: string
}

function getImageByTypeAndViewID(images: ImageRO[] | undefined, typeID: string, viewID?: string): ImageRO | undefined {
  return images?.find((image) => image.typeID === typeID && (!viewID || image.viewID === viewID))
}

function transformImages(images: ImageRO[] | undefined): string[] {
  if (!images) {
    return []
  }

  const primaryImage = images.find((image) => image.primaryImage && image.typeID === "S")

  const otherImages = images
    .filter((image) => image.typeID === "S" && !image.primaryImage)
    .map((image) => image.viewID || "")
    .filter((viewID) => viewID !== "")

  if (primaryImage) {
    return [primaryImage.viewID || "", ...otherImages]
  }

  return otherImages
}

type ProductImageContextType = {
  viewID: string
  image_gallery?: ImageRO[]
  setViewID: Dispatch<SetStateAction<string>>
}

const defaultContext = {
  viewID: "",
  image_gallery: [],
  setViewID: (id: string) => undefined,
}

const ProductImageContext = createContext<ProductImageContextType>(defaultContext)

const UBProductImageGallery = ({ blok, ...restProps }: UBProductImageGalleryProps) => {
  const product = restProps.product
  const gallery_image_ids = transformImages(product.image_gallery)
  const primaryImage = product.image_gallery
    ? product.image_gallery.find((image) => image.primaryImage && image.typeID === "S")
    : undefined
  const [viewID, setViewID] = useState<string>(primaryImage?.viewID || "")
  const context = {
    viewID,
    image_gallery: product.image_gallery,
    setViewID,
  }

  return product ? (
    <div {...storyblokEditable(blok)}>
      <ProductImageContext.Provider value={context}>
        <ImageMagnify />
        <Carousel className="max-w-s mt-2 w-full">
          <CarouselContent>
            {gallery_image_ids.map((id, key) => (
              <CarouselItem key={key} className={"basis-1/4"}>
                <GalleryImage id={id} />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious bannermode={false} fullwidth={false} />
          <CarouselNext bannermode={false} fullwidth={false} />
        </Carousel>
      </ProductImageContext.Provider>
    </div>
  ) : (
    <p>Product not found</p>
  )
}

const GalleryImage: FC<GalleryImageProps> = ({ id }) => {
  const { setViewID, image_gallery } = useContext(ProductImageContext)
  const image = getImageByTypeAndViewID(image_gallery, "S", id)
  const src = image?.effectiveUrl || ""
  const width = image?.imageActualWidth || 100
  const height = image?.imageActualHeight || 100
  const alt = image?.name || ""

  return (
    <button className={"max-h-16 w-full cursor-pointer rounded-sm border p-1"} onClick={() => setViewID(id)}>
      <Image className="size-full object-contain" src={src} alt={alt} width={width} height={height} />
    </button>
  )
}

const ImageMagnify = () => {
  const { viewID, image_gallery } = useContext(ProductImageContext)
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const image_box_ref = useRef(null)
  const zoom_box_ref = useRef(null)
  const zoom_view_ref = useRef(null)
  const [zoomBoxWidth, setZoomBoxWidth] = useState(0)
  const [zoomBoxHeight, setZoomBoxHeight] = useState(0)
  const [zoomBoxX, setZoomBoxX] = useState(0)
  const [zoomBoxY, setZoomBoxY] = useState(0)

  const preview_image = getImageByTypeAndViewID(image_gallery, "M", viewID)
  const zoom_image = getImageByTypeAndViewID(image_gallery, "ZOOM", viewID)

  function onMouseMove(event: MouseEvent) {
    const image_box: HTMLImageElement | null = image_box_ref.current
      ? (image_box_ref.current as HTMLImageElement)
      : null
    const zoom_box: HTMLElement | null = zoom_box_ref.current ? (zoom_box_ref.current as HTMLElement) : null
    const zoom_view: HTMLImageElement | null = zoom_view_ref.current
      ? (zoom_view_ref.current as HTMLImageElement)
      : null

    if (zoom_box && image_box && zoom_view) {
      const bound = image_box.getBoundingClientRect()

      const maxX = bound.x + bound.width - zoom_box.clientWidth
      const maxY = bound.y + bound.height - zoom_box.clientHeight

      let newX = event.clientX - zoom_box.clientWidth / 2
      let newY = event.clientY - zoom_box.clientHeight / 2

      newX = Math.max(bound.x, Math.min(newX, maxX))
      newY = Math.max(bound.y, Math.min(newY, maxY))

      const width_ratio = zoom_image?.imageActualWidth ? zoom_image.imageActualWidth / bound.width : 1
      const height_ratio = zoom_image?.imageActualHeight ? zoom_image.imageActualHeight / bound.height : 1

      setZoomBoxX((newX - bound.x) * width_ratio * -1)
      setZoomBoxY((newY - bound.y) * height_ratio * -1)

      setMousePosition({
        x: newX,
        y: newY,
      })
    }
  }

  useEffect(() => {
    const image_box: HTMLImageElement | null = image_box_ref.current
      ? (image_box_ref.current as HTMLImageElement)
      : null
    const zoom_view: HTMLImageElement | null = zoom_view_ref.current
      ? (zoom_view_ref.current as HTMLImageElement)
      : null

    if (image_box && zoom_view) {
      const width_ratio = zoom_view.clientWidth / (zoom_image?.imageActualWidth || 1)
      const height_ratio = zoom_view.clientHeight / (zoom_image?.imageActualHeight || 1)

      setZoomBoxWidth(image_box.clientWidth * width_ratio)
      setZoomBoxHeight(image_box.clientHeight * height_ratio)
    }
  }, [zoom_image?.imageActualWidth, zoom_image?.imageActualHeight])

  return (
    <div className="group relative flex">
      <div onMouseMove={onMouseMove} className="hover-initiator size-96 rounded border p-2">
        <div className="flex h-full flex-col justify-center">
          <Image
            ref={image_box_ref}
            src={preview_image?.effectiveUrl || ""}
            alt={"Preview"}
            width={preview_image?.imageActualWidth}
            height={preview_image?.imageActualHeight}
            priority={true}
            className="size-full object-contain"
          />
        </div>
        <div
          ref={zoom_box_ref}
          style={{
            left: mousePosition.x,
            top: mousePosition.y,
            width: zoomBoxWidth,
            height: zoomBoxHeight,
          }}
          className="fixed hidden h-24 w-40 border border-gray-400 bg-white opacity-0 hover:opacity-40 lg:block"
        ></div>
      </div>
      <div
        ref={zoom_view_ref}
        style={{
          backgroundImage: `url(${zoom_image?.effectiveUrl || ""})`,
          backgroundPositionX: `${zoomBoxX}px`,
          backgroundPositionY: `${zoomBoxY}px`,
        }}
        className="hover-content absolute ml-[25rem] hidden h-96 w-[36rem] rounded border bg-white bg-no-repeat object-contain lg:block"
      ></div>
    </div>
  )
}

export default UBProductImageGallery
