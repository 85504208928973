import React from "react"
import { AlignLeftIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { UserData } from "@/lib/intershop/interfaces/user/user"
import { Category } from "@/lib/storefront/category/category"
import { cn } from "@/lib/utils"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/components"
import { UbHeaderLinkStoryblok, UbHeaderStoryblok } from "@/components/storyblok/component-types"
import StoryblokPrivateCustomerToggle from "@/components/storyblok/header/StoryblokPrivateCustomerToggle"

import StoryblokMultilink from "../common/StoryblokMultilink"
import { UbHeaderLinkStoryblokExtended } from "../UBHeaderClient"
import StoryblokCategories from "./StoryblokCategories"
import StoryblokMobileCategories from "./StoryblokMobileCategories"

interface StoryblokMenuBarProps {
  blok: UbHeaderStoryblok
  isMobileVisible: boolean
  categories: Category[]
  navigation: UbHeaderLinkStoryblokExtended[]
  userData: UserData | undefined
  isB2BCustomer?: boolean
}

export default function StoryblokMenuBar({
  blok,
  isMobileVisible,
  categories,
  navigation,
  userData,
  isB2BCustomer,
}: StoryblokMenuBarProps) {
  const t = useTranslations("storefront")
  return (
    <div className="w-full bg-black">
      {isMobileVisible ? (
        <div className="absolute flex size-full flex-col gap-0 bg-white lg:hidden lg:gap-0.5">
          <div className={"m-2"}>
            <StoryblokPrivateCustomerToggle
              userData={userData}
              isMobileVisible={isMobileVisible}
              isB2BCustomer={isB2BCustomer}
            />
          </div>
          <StoryblokMobileCategories blok={blok} categories={categories} />
          {navigation.map((ubHeaderLinkItem) => (
            <StoryblokMultilink
              link={ubHeaderLinkItem.multilink}
              key={ubHeaderLinkItem.label}
              className={cn("bg-black px-5 py-1.5  text-lg font-semibold text-white ", {
                "bg-primary border lg:border-2 border-white hover:bg-primary-hover hover:text-white":
                  ubHeaderLinkItem.highlight,
              })}
            >
              {ubHeaderLinkItem.label}
            </StoryblokMultilink>
          ))}
        </div>
      ) : (
        <div className="mx-auto hidden w-full  max-w-7xl justify-center px-4 sm:px-6 lg:flex lg:px-8">
          <div className="flex w-full max-w-7xl items-center justify-start font-bold text-white">
            <NavigationMenu className="bg-none">
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger
                    className="rounded-none bg-transparent p-0 pr-12
                    hover:bg-black
                  hover:text-white focus:bg-black focus:text-white data-[active]:bg-black data-[open]:bg-black data-[state=open]:bg-black data-[active]:text-white data-[open]:text-white data-[state=open]:text-white"
                  >
                    <div className="flex min-w-36  flex-row items-center gap-2 ">
                      <AlignLeftIcon className={"h-[20px] text-white"} />
                      <p className="whitespace-nowrap text-lg font-semibold uppercase">{t("header.all_categories")}</p>
                    </div>
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className={"w-full rounded-none p-12 shadow-none"}>
                    <StoryblokCategories blok={blok} categories={categories} />
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>

            {navigation.map((ubHeaderLinkItem) => (
              <StoryblokMultilink
                link={ubHeaderLinkItem.multilink}
                key={ubHeaderLinkItem.label}
                className={cn(
                  "flex h-full items-center whitespace-nowrap rounded px-6 text-lg font-semibold leading-6 text-white hover:bg-white hover:text-black",
                  {
                    "bg-primary rounded border lg:border-2 border-white hover:bg-primary-hover hover:text-white":
                      ubHeaderLinkItem.highlight,
                    hidden: (ubHeaderLinkItem.hideB2B && isB2BCustomer) || (ubHeaderLinkItem.hideB2C && !isB2BCustomer),
                  }
                )}
              >
                {ubHeaderLinkItem.label}
              </StoryblokMultilink>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
