import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "../helpers/utils"
import {
  bgColorDef,
  gapDef,
  itemAlignDef,
  marginDef,
  paddingDef,
  type BackgroundColorProps,
  type GapProps,
  type ItemAlignProps,
  type MarginProps,
  type PaddingProps,
} from "../props"

const flexVariants = cva("flex", {
  variants: {
    direction: {
      row: "flex-row",
      "row-reverse": "flex-row-reverse",
      col: "flex-col",
      "col-reverse": "flex-col-reverse",
    },
    // You can use variant modifiers to target media queries such as responsive breakpoint lg
    directionLg: {
      row: "lg:flex-row",
      "row-reverse": "lg:flex-row-reverse",
      col: "lg:flex-col",
      "col-reverse": "lg:flex-col-reverse",
    },
  },
  defaultVariants: {
    direction: "row",
    directionLg: "row",
  },
})

type FlexElement = React.ElementRef<"div">

interface FlexProps
  extends VariantProps<typeof flexVariants>,
    GapProps,
    ItemAlignProps,
    BackgroundColorProps,
    MarginProps,
    PaddingProps,
    React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
  wrap?: boolean
}

const Flex = React.forwardRef<FlexElement, FlexProps>(
  (
    {
      className,
      margin,
      marginRight,
      marginBottom,
      marginLeft,
      marginTop,
      marginX,
      marginY,
      marginLg,
      marginRightLg,
      marginBottomLg,
      marginLeftLg,
      marginTopLg,
      marginXLg,
      marginYLg,
      padding,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingTop,
      paddingX,
      paddingY,
      paddingLg,
      paddingRightLg,
      paddingBottomLg,
      paddingLeftLg,
      paddingTopLg,
      paddingXLg,
      paddingYLg,
      bgColor,
      gap,
      gapLg,
      direction,
      directionLg,
      alignItems,
      justifyItems,
      wrap = true,
      asChild = false,
      ...props
    },
    forwardedRef
  ) => {
    const Comp = asChild ? Slot : "div"

    return (
      <Comp
        {...props}
        ref={forwardedRef}
        className={cn(
          {
            "flex-wrap": wrap,
          },
          marginDef({
            margin,
            marginRight,
            marginBottom,
            marginLeft,
            marginTop,
            marginX,
            marginY,
            marginLg,
            marginRightLg,
            marginBottomLg,
            marginLeftLg,
            marginTopLg,
            marginXLg,
            marginYLg,
          }),
          paddingDef({
            padding,
            paddingRight,
            paddingBottom,
            paddingLeft,
            paddingTop,
            paddingX,
            paddingY,
            paddingLg,
            paddingRightLg,
            paddingBottomLg,
            paddingLeftLg,
            paddingTopLg,
            paddingXLg,
            paddingYLg,
          }),
          bgColorDef({ bgColor }),
          gapDef({ gap, gapLg }),
          itemAlignDef({ justifyItems, alignItems }),
          flexVariants({ direction, directionLg }),
          className
        )}
      />
    )
  }
)
Flex.displayName = "Flex"

export { Flex }
export type { FlexProps }
