"use client"

import React from "react"
import Link from "next/link"
import {
  DEFAULT_SEARCH_RESULT_GROUP_SIZE,
  DEFAULT_SEARCH_RESULT_PAGE_SIZES,
  DEFAULT_SEARCH_RESULT_SORT_ORDER,
  DEFAULT_SEARCH_RESULT_VISIBLE_GROUPS,
  SEARCH_PARAMS_PAGE,
  SEARCH_PARAMS_PAGE_SIZE,
  SEARCH_PARAMS_SORT_ORDER,
} from "@/constants/ui"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from "@radix-ui/react-icons"
import { useTranslations } from "next-intl"

import { cn } from "@/lib/utils"
import { getPaginationInfo, PaginationSerachParams } from "@/components/search-and-category/pagination-info"

interface SearchResultPaginationProps {
  hitcount: number
  searchParams: URLSearchParams | null
  paginationSearchParams: PaginationSerachParams
}

const SearchResultPaginationClient = (props: SearchResultPaginationProps) => {
  const t = useTranslations("storefront")
  const { setIsLoading } = useUbStorefrontState()

  const lastPage = Math.ceil(props.hitcount / props.paginationSearchParams.pageSize)
  const paginationInfo = getPaginationInfo(
    DEFAULT_SEARCH_RESULT_GROUP_SIZE,
    props.paginationSearchParams.page,
    lastPage,
    DEFAULT_SEARCH_RESULT_VISIBLE_GROUPS
  )
  const previousPage = Math.max(props.paginationSearchParams.page - 1, 1)
  const nextPage = Math.min(props.paginationSearchParams.page + 1, lastPage)

  const href = (targetPage: number) => {
    const newSearchParams = new URLSearchParams(props.searchParams ?? undefined)
    newSearchParams.set(SEARCH_PARAMS_PAGE, targetPage.toString())
    props.paginationSearchParams.pageSize === DEFAULT_SEARCH_RESULT_PAGE_SIZES[0]
      ? newSearchParams.delete(SEARCH_PARAMS_PAGE_SIZE)
      : newSearchParams.set(SEARCH_PARAMS_PAGE_SIZE, props.paginationSearchParams.pageSize.toString())
    props.paginationSearchParams.sortOrder === DEFAULT_SEARCH_RESULT_SORT_ORDER[0]
      ? newSearchParams.delete(SEARCH_PARAMS_SORT_ORDER)
      : newSearchParams.set(SEARCH_PARAMS_SORT_ORDER, props.paginationSearchParams.sortOrder.toString())
    return "?" + newSearchParams.toString()
  }

  return (
    <div className="flex flex-row gap-x-4">
      <Link
        prefetch={false}
        onClick={() => setIsLoading(true)}
        className={cn("pt-[0.19rem]", { "pointer-events-none text-gray2": props.paginationSearchParams.page === 1 })}
        href={href(1)}
        title={t("pagination.first")}
      >
        <DoubleArrowLeftIcon className="size-6" />
      </Link>
      <Link
        prefetch={false}
        onClick={() => setIsLoading(true)}
        className={cn("pt-[0.19rem]", { "pointer-events-none text-gray2": props.paginationSearchParams.page === 1 })}
        href={href(previousPage)}
        title={t("pagination.previous")}
      >
        <ChevronLeftIcon className="size-6" />
      </Link>
      {paginationInfo.map((piItem) => (
        <Link
          prefetch={false}
          onClick={() => setIsLoading(true)}
          className={cn("pt-[0.19rem]", {
            "size-8 text-center rounded-full bg-primary text-white":
              piItem.start === props.paginationSearchParams.page && !(props.paginationSearchParams.page === lastPage),
            "size-8 text-center rounded-full bg-gray-200 text-gray-300":
              piItem.start === props.paginationSearchParams.page && props.paginationSearchParams.page === lastPage,
          })}
          href={href(piItem.start)}
          key={"SearchResultGridPage_" + piItem.start}
          title={
            piItem.start === piItem.end
              ? t("pagination.page", { page: piItem.start, total: lastPage })
              : t("pagination.pages", { page: piItem.start + "-" + piItem.end, total: lastPage })
          }
        >
          {piItem.start === piItem.end ? piItem.start : piItem.start + "-" + piItem.end}
        </Link>
      ))}
      <Link
        prefetch={false}
        onClick={() => setIsLoading(true)}
        className={cn("pt-[0.19rem]", {
          "pointer-events-none text-gray2": props.paginationSearchParams.page === lastPage,
        })}
        href={href(nextPage)}
        title={t("pagination.next")}
      >
        <ChevronRightIcon className="size-6" />
      </Link>
      <Link
        prefetch={false}
        onClick={() => setIsLoading(true)}
        className={cn("pt-[0.19rem]", {
          "pointer-events-none text-gray2": props.paginationSearchParams.page === lastPage,
        })}
        href={href(lastPage)}
        title={t("pagination.last")}
      >
        <DoubleArrowRightIcon className="size-6" />
      </Link>
    </div>
  )
}

export default SearchResultPaginationClient
