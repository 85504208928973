"use client"

import React, { useState } from "react"
import Image from "next/image"
import { ChevronRightIcon, CircleAlertIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { getCategoryLinkByName } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"
import { Button } from "@/components/ui/components"
import StoryblokAssetClient from "@/components/storyblok/common/StoryblokAssetClient"
import StoryblokMultilink from "@/components/storyblok/common/StoryblokMultilink"
import { UbHeaderStoryblok } from "@/components/storyblok/component-types"

export interface StoryblokCategoriesProps {
  blok: UbHeaderStoryblok
  categories: Category[]
}

export default function StoryblokCategories({ blok, categories }: StoryblokCategoriesProps) {
  const t = useTranslations("storefront")

  const MAX_NUMBER_SUBSUBCATEGORIES = 5

  const [selectedMainCategory, setSelectedMainCategory] = useState<Category | undefined>(undefined)
  //index of selected main category to show the content from storyblok - starting with 1
  const [selectedMainCategoryIndex, setSelectedMainCategoryIndex] = useState<number>(0)

  const timeoutIds = {}

  const onMouseEnter = (category: Category, index: number) => {
    timeoutIds[category.id] = setTimeout(
      (category) => {
        if (category.subCategories.length) {
          setSelectedMainCategory(category)
          setSelectedMainCategoryIndex(index + 1)
        }
      },
      500,
      category
    )
  }

  const onMouseLeave = (category: Category) => {
    if (timeoutIds[category.id]) {
      clearTimeout(timeoutIds[category.id])
      timeoutIds[category.id] = undefined
    }
  }

  return (
    <div className={"flex flex-col gap-2"}>
      <div className="flex flex-row gap-x-10">
        {/* Main categories */}
        <div className="flex min-w-52 flex-col gap-4">
          {categories.map((mainCategory: Category, index: number) => (
            <div
              className="flex flex-row items-center gap-2"
              key={mainCategory.id}
              id={mainCategory.id}
              onMouseEnter={() => onMouseEnter(mainCategory, index)}
              onMouseLeave={() => onMouseLeave(mainCategory)}
            >
              {mainCategory.image_url && (
                <Image
                  className="size-8"
                  src={mainCategory.image_url}
                  width={100}
                  height={100}
                  alt={mainCategory.name}
                />
              )}

              <a href={getCategoryLinkByName(mainCategory.name)} className="text-md flex-1 p-2 font-semibold">
                {mainCategory.name}
              </a>

              {!!mainCategory.subCategories.length && <ChevronRightIcon className="size-4 justify-items-end" />}
            </div>
          ))}
        </div>

        {/* Sub categories - conditionally visible dependent on main category */}
        {!!selectedMainCategory?.subCategories.length && (
          <div className={"flex flex-row gap-2"}>
            <div className="grid w-[40rem] grid-cols-3 gap-2 gap-x-4">
              {selectedMainCategory.subCategories.map((subCategory: Category) => (
                <div key={subCategory.id} className="flex flex-col gap-0">
                  <a href={getCategoryLinkByName(subCategory.name)} className="border-b py-2 text-base font-semibold">
                    {subCategory.name}
                  </a>

                  <div className="pt-2">
                    {subCategory.subCategories.slice(0, MAX_NUMBER_SUBSUBCATEGORIES).map((subsubCategory) => (
                      <div key={subsubCategory.id} className="">
                        <a
                          href={getCategoryLinkByName(subsubCategory.name)}
                          className="flex-1 py-2 text-sm font-normal"
                        >
                          {subsubCategory.name}
                        </a>
                      </div>
                    ))}

                    {subCategory.subCategories.length > MAX_NUMBER_SUBSUBCATEGORIES && (
                      <div className="font-semibold">
                        <a href={getCategoryLinkByName(subCategory.name)} className="flex-1 py-2 text-sm font-semibold">
                          {t("category.more")}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {blok.showCategoryTeaserContent && blok["cat_" + selectedMainCategoryIndex + "_show_teaser"] && (
              <StoryblokMultilink link={blok["cat_" + selectedMainCategoryIndex + "_teaser_link"]}>
                <div className={"hidden w-52 lg:block"}>
                  <div className={"flex flex-col bg-gray-gray3"}>
                    <StoryblokAssetClient asset={blok["cat_" + selectedMainCategoryIndex + "_teaser_image"]} />
                    <p className={"p-2 text-xs font-semibold"}>
                      {blok["cat_" + selectedMainCategoryIndex + "_teaser_text"]}
                    </p>
                    {blok["cat_" + selectedMainCategoryIndex + "_teaser_cta"] &&
                      blok["cat_" + selectedMainCategoryIndex + "_teaser_cta"] !== "" && (
                        <div className={"w-full px-2 pb-2"}>
                          <Button asChild size={"sm"} className={"w-full"}>
                            <span>{blok["cat_" + selectedMainCategoryIndex + "_teaser_cta"]}</span>
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
              </StoryblokMultilink>
            )}
          </div>
        )}
      </div>
      {blok.showCategoryTeaserContent &&
        blok["cat_" + selectedMainCategoryIndex + "_show_teaser"] &&
        blok["cat_" + selectedMainCategoryIndex + "_service_teaser_text"] &&
        blok["cat_" + selectedMainCategoryIndex + "_service_teaser_text"] !== "" && (
          <div className={"relative top-8 w-full"}>
            <StoryblokMultilink
              className={"flex w-full justify-end rounded-full border border-primary"}
              link={blok["cat_" + selectedMainCategoryIndex + "_service_teaser_link"]}
            >
              <div className={"flex w-full items-center justify-center gap-4 "}>
                <CircleAlertIcon className={"size-6 text-primary"} />
                <p>{blok["cat_" + selectedMainCategoryIndex + "_service_teaser_text"]}</p>
              </div>
              <div className={"flex aspect-square w-12 items-center justify-center rounded-r-full bg-primary"}>
                <ChevronRightIcon className="size-8 text-white" />
              </div>
            </StoryblokMultilink>
          </div>
        )}
    </div>
  )
}
