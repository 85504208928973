"use client"

import React from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { Button } from "@/components/ui/components"

export function FilterButtonClient() {
  const t = useTranslations("storefront")

  const { setFilterShown } = useUbStorefrontState()

  function handleFilter() {
    setFilterShown(true)
  }

  return (
    <div>
      <Button size={"lg"} onClick={handleFilter}>
        {t("common.filter")}
      </Button>
    </div>
  )
}
