import { getCountry, getLanguage, getLanguageFromSbLocale } from "@/lib/link-utils/get-sb-locale"
import { logger } from "@/lib/logger"

// Workaround for not being able to solve typing correctly: Comment out :-(
// interface StoryblokSwissLocaleSwitchProps {
//   urisByLanguage: Record<string, string> // key: language => value: Uri
// }

export default function StoryblokSwissLocaleSwitch({ ...restProps }) {
  const switchToSbLocale = getLanguage() === "de" ? "fr-ch" : "de-ch"

  const switchToLanguage = getLanguageFromSbLocale(switchToSbLocale)
  const switchToLanguageDisplay = switchToLanguage.toUpperCase()

  const urlsBySbLocale = restProps.urlsBySbLocale as Record<string, string>
  const switchLanguageUri = getUriToSwitchTo(urlsBySbLocale, switchToSbLocale)

  return (
    <>
      {getCountry() === "ch" && (
        <div className={"ml-8 flex justify-end"}>
          <a className={"flex items-center justify-between gap-1"} href={switchLanguageUri}>
            <div className="relative aspect-square h-5 rounded-sm bg-red-600">
              <span className="absolute inset-x-[40%] inset-y-[10%] bg-white"></span>
              <span className="absolute inset-x-[10%] inset-y-[40%] bg-white"></span>
            </div>
            <span>{switchToLanguageDisplay}</span>
          </a>
        </div>
      )}
    </>
  )
}
function getUriToSwitchTo(urlsBySbLocale: Record<string, string>, switchToSbLocale: string) {
  // fallback to home page
  if (!urlsBySbLocale) {
    return "/"
  }
  return urlsBySbLocale[switchToSbLocale] ?? "/"
}
