"use client"

import React, { useState } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { XCircleIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { FacetData } from "@/lib/factfinder/factfinder-interfaces"
import { getResetLinkToSearchResultOrCategory } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger, Button } from "@/components/ui/components"
import { cn } from "@/components/ui/helpers/utils"
import CategoryTree from "@/components/search-and-category/CategoryTree"
import Facet from "@/components/search-and-category/Facet"

import { FacetForBrand } from "./FacetForBrand"
import { FacetForPrice } from "./FacetForPrice"

interface FacetListProps {
  facets: FacetData[]
  query?: string
  baseCategory?: Category
  brandSlug?: string
  categoryAsTree?: boolean
  categoryOnly?: boolean
  isFiltered: boolean
  hitcount: number
}

// see ub-intershop-intern: FilterList.isml
const FACET_COUNT_TO_SHOW_IN_SEARCH = 11
const FACET_COUNT_TO_SHOW_IN_CATEGORIES = 12
const FACET_COUNT_TO_SHOW_EXPANDED = 4

function FacetList(props: FacetListProps) {
  const t = useTranslations("storefront")

  // const hasSelectedFacets = props.facets?.some((facetData) => facetData.selectedFacetElements.length > 0)
  const linkToResetAllFilters = getResetLinkToSearchResultOrCategory(props.query, props.baseCategory, props.brandSlug)

  const openFacets: string[] = props.facets?.slice(0, FACET_COUNT_TO_SHOW_EXPANDED).map((facet) => facet.name) || []

  const hasAnyFacets = !!props.facets?.length
  let needResetFilterButton = !hasAnyFacets || props.isFiltered
  if (props.hitcount <= 0 && !props.isFiltered) {
    needResetFilterButton = false
  }

  const [showAll, setShowAll] = useState(false)
  let facetCountToShow: number = props.baseCategory ? FACET_COUNT_TO_SHOW_IN_CATEGORIES : FACET_COUNT_TO_SHOW_IN_SEARCH
  const hiddenCount = (props.facets?.length || 0) - facetCountToShow
  if (hiddenCount <= 1) {
    facetCountToShow = Infinity
  }

  const { filterShown, setFilterShown } = useUbStorefrontState()

  function handleCloseFilter() {
    setFilterShown(false)
  }

  return (
    <div
      className={cn("hidden  lg:block", {
        block: filterShown,
      })}
    >
      <div
        className={cn(
          "fixed  inset-x-0 top-0 hidden h-12 items-center justify-center border-b border-b-gray-200 bg-white",
          {
            "flex z-[300] lg:hidden": filterShown,
          }
        )}
      >
        <p className={"text-lg font-bold"}> {t("common.filter")}</p>
        <XCircleIcon
          className={cn("absolute right-0 top-1 m-2 hidden size-6 cursor-pointer bg-white lg:hidden", {
            block: filterShown,
          })}
          onClick={handleCloseFilter}
        />
      </div>

      <div
        className={cn(
          "fixed inset-x-0 bottom-32 top-12 hidden bg-ub-background lg:static lg:block lg:overflow-y-auto",
          {
            "block z-[300]  overflow-y-scroll": filterShown,
          }
        )}
      >
        <div className="relative flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            {needResetFilterButton && !hasAnyFacets && (
              <a className="mb-4 hidden lg:block " href={linkToResetAllFilters}>
                {t("common.reset_filters")}
              </a>
            )}
            {hasAnyFacets && (
              <AccordionRoot
                className={cn("min-w-80 max-w-80 pt-2", { "max-w-full pt-0": filterShown })}
                type="multiple"
                defaultValue={openFacets}
              >
                {needResetFilterButton && (
                  <a className="mb-4 hidden lg:block" href={linkToResetAllFilters}>
                    {t("common.reset_filters")}
                  </a>
                )}

                <div>
                  {props.facets &&
                    props.facets.map((facet: FacetData, index: number) => (
                      <div
                        key={index}
                        className={
                          ((index < facetCountToShow || showAll) && !props.categoryOnly) ||
                          (props.categoryOnly && index === 0)
                            ? "block"
                            : "hidden"
                        }
                      >
                        {/*  {props.categoryOnly && <CategoryTree category={props.baseCategory} facet={facet} />}
                        {!props.categoryOnly && <FacetListItem index={index} facet={facet} {...props} />}*/}
                        <FacetListItem index={index} facet={facet} {...props} />
                      </div>
                    ))}
                  <div
                    className={cn("block", {
                      hidden: props.categoryOnly,
                    })}
                  >
                    {props.facets && props.facets.length > facetCountToShow && (
                      <button
                        className="my-2 w-full border border-gray-200 bg-white p-2 text-xs text-gray-500 lg:p-4"
                        onClick={() => setShowAll(!showAll)}
                      >
                        {showAll ? t("common.show_less") : t("common.show_further_count", { count: hiddenCount })}
                      </button>
                    )}
                  </div>
                </div>
              </AccordionRoot>
            )}
          </div>
        </div>
      </div>
      <div
        className={cn(
          "fixed inset-x-0 bottom-0 hidden h-32 flex-col items-center justify-center gap-2 border-t border-t-gray-200 bg-white px-8 ",
          {
            "flex z-[300] lg:hidden": filterShown,
          }
        )}
      >
        <Button className={"w-full"} size={"lg"} onClick={handleCloseFilter}>
          {t("common.apply_filters", { hitcount: props.hitcount })}
        </Button>

        <a className={"mb-4 block w-full"} href={linkToResetAllFilters}>
          <Button className={"w-full"} size={"lg"} variant={needResetFilterButton && !hasAnyFacets ? "ghost" : "black"}>
            {t("common.reset_filters")}
          </Button>
        </a>
      </div>
    </div>
  )
}

interface FacetListItemProps {
  index: number
  facet: FacetData
  query?: string
  baseCategory?: Category
  brandSlug?: string
  categoryAsTree?: boolean
}

function FacetListItem({ index, facet, ...props }: FacetListItemProps) {
  const isBrand = facet.fieldName === "brand"
  const isPrice = facet.fieldName === "price"
  const isCategory = facet.fieldName === "category"
  const showCategoryTree = props.categoryAsTree && isCategory
  const t = useTranslations("storefront")

  return (
    <AccordionItem
      key={index}
      className="mb-4 rounded-sm border border-gray-200 bg-white p-2 font-bold"
      value={facet.name}
    >
      <AccordionTrigger className="h-10 px-1 text-start shadow-none">
        {isCategory ? t("suggestion.result.header.categories") : facet.name}
      </AccordionTrigger>
      <AccordionContent className="text-sm font-normal">
        {isBrand && (
          <FacetForBrand
            query={props.query}
            baseCategory={props.baseCategory}
            brandSlug={props.brandSlug}
            facetData={facet}
          />
        )}
        {isPrice && (
          <FacetForPrice
            query={props.query}
            baseCategory={props.baseCategory}
            brandSlug={props.brandSlug}
            facetData={facet}
          />
        )}
        {showCategoryTree && props.baseCategory && <CategoryTree category={props.baseCategory} facet={facet} />}
        {!isBrand && !isPrice && !showCategoryTree && (
          <Facet query={props.query} baseCategory={props.baseCategory} brandSlug={props.brandSlug} facetData={facet} />
        )}
      </AccordionContent>
    </AccordionItem>
  )
}

export default FacetList
