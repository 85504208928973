export function isDevMode(): boolean {
  return process.env.NODE_ENV === "development"
}

export function isProdMode(): boolean {
  return process.env.NODE_ENV === "production"
}

export function isTestMode(): boolean {
  return process.env.NODE_ENV === "test"
}
