"use client"

import { useEffect, useState } from "react"
import { sendGTMEvent } from "@next/third-parties/google"
import { list } from "postcss"

import { ReducedProductData } from "@/lib/storefront/product/product"

export interface ProductImpressionsTrackingProps {
  products: ReducedProductData[]
  list: string
}

function determineVariantString(product: ReducedProductData) {
  let result = ""
  if (product && product.variableVariationAttributes) {
    for (let i = 0; i < product.variableVariationAttributes; i++) {
      const attributeValue = product.variableVariationAttributes[i]?.value
      if (attributeValue) {
        result += attributeValue + "/"
      }
    }
  }
  return result
}

const ProductImpressionsTrackingClient = (props: ProductImpressionsTrackingProps) => {
  const [tracked, setTracked] = useState(false)

  useEffect(() => {
    if (!tracked) {
      console.log("Send product impression tracking to list: " + props.list)

      const productPromotionData = {
        event: "product_impressions",
        ecommerce: {
          currencyCode: "EUR",
          impressions: [] as any[],
        },
      }

      for (let i = 0; i < props.products.length; i++) {
        const product = props.products[i]

        // @ts-ignore
        productPromotionData.ecommerce.impressions.push({
          brand: product.brand ?? "",
          category: product.category,
          id: product.id,
          list: props.list,
          name: product.title,
          position: i + 1,
          variant: determineVariantString(product),
        })
      }

      sendGTMEvent(productPromotionData)
      setTracked(true)
    }
  }, [])

  return <span className={"hidden"}>ProductImpressionsTrackingClient</span>
}
export default ProductImpressionsTrackingClient

/*
{
   "event": "product_impressions",
   "ecommerce": {
      "currencyCode": "EUR",
      "impressions": [
         {
            "brand": "UDOBÄR",
            "category": "Umwelt",
            "id": "A273075",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Mülltonne Citybac Classic aus recyceltem Material 120 l",
            "position": 34,
            "variant": "fernblau // RAL5023/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Abfallentsorgung",
            "id": "A273074",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Mülltonne Citybac Classic aus recyceltem Material 120 l",
            "position": 35,
            "variant": "zinkgelb // RAL1018/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Schnell  preiswert",
            "id": "A273077",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Mülltonne Citybac Classic aus recyceltem Material 120 l",
            "position": 36,
            "variant": "resedagrün // RAL6011/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Betrieb",
            "id": "A273526",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Mülltonne Citybac Classic aus recyceltem Material 120 l",
            "position": 37,
            "variant": "blassbraun // RAL8025/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Lager Mülltonnen",
            "id": "A273533",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Mülltonne Citybac aus recyceltem Material 120 l",
            "position": 38
         },
         {
            "brand": "UDOBÄR",
            "category": "Schnell  preiswert",
            "id": "A273538",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Scharnierdeckel Citybac für Mülltonne gelb",
            "position": 39,
            "variant": "gelb/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Betrieb",
            "id": "A273537",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Scharnierdeckel Citybac für Mülltonne blau",
            "position": 40,
            "variant": "blau/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Lager Mülltonnen",
            "id": "A273534",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Scharnierdeckel Citybac für Mülltonne schwarzgrau",
            "position": 41,
            "variant": "schwarzgrau/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Aktionskategorien",
            "id": "A273536",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Scharnierdeckel Citybac für Mülltonne braun",
            "position": 42,
            "variant": "braun/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Schnell  preiswert",
            "id": "A273538",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Scharnierdeckel Citybac für Mülltonne gelb",
            "position": 43,
            "variant": "gelb/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Betrieb",
            "id": "A273537",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Scharnierdeckel Citybac für Mülltonne blau",
            "position": 44,
            "variant": "blau/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Reinigungstücher",
            "id": "A273963",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Reinigungstücher Multiuse Bio 100 Tücher Stoff",
            "position": 45,
            "variant": "Pflanzenfasergewebe/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Reinigungstücher",
            "id": "A273961",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Reinigungstücher Multiuse 100 Tücher Stoff",
            "position": 46,
            "variant": "Gewebe/"
         },
         {
            "brand": "UDOBÄR",
            "category": "Reinigungstücher",
            "id": "A273962",
            "list": "Category Schnelllieferprogramm",
            "name": "UDOBÄR Reinigungstücher Power Scrub 80 Tücher Stoff",
            "position": 47
         },
         {
            "brand": "PAVOY",
            "category": "Wäscheschränke",
            "id": "E13709",
            "list": "Category Zuletzt angesehen",
            "name": "PAVOY Wäscheschrank Basis RAL7035",
            "position": 1
         },
         {
            "brand": "B-Safety",
            "category": "Seifen Cremes und Desinfektionsmittel",
            "id": "A147347",
            "list": "Category Zuletzt angesehen",
            "name": "B-Safety Cremeseife PLUM Fresh",
            "position": 2,
            "variant": ""
         },
         {
            "category": "Persönliche Schutzausrüstung",
            "id": "E19617",
            "list": "Category Zuletzt angesehen",
            "name": "Nitril-Schutzhandschuhe Ultranitril",
            "position": 3
         },
         {
            "brand": "CWS",
            "category": "Seifen Cremes und Desinfektionsmittel",
            "id": "A523504",
            "list": "Category Zuletzt angesehen",
            "name": "CWS Seifenschaumkonzentrat Standard",
            "position": 4
         },
         {
            "category": "Persönliche Schutzausrüstung",
            "id": "E20428",
            "list": "Category Zuletzt angesehen",
            "name": "Damen-Arbeitsmantel mit Druckknöpfen und Rückenschlitz",
            "position": 5
         },
         {
            "category": "Persönliche Schutzausrüstung",
            "id": "A221396",
            "list": "Category Zuletzt angesehen",
            "name": "Einweg-Schutzoverall weiß Größe XL",
            "position": 6,
            "variant": "XL/"
         }
      ]
   },
   "gtm.uniqueEventId": 155
}*/
