"use client"

import React, { useState } from "react"
import Link from "next/link"
import { useTranslations } from "next-intl"

import { cn } from "@/components/ui/helpers/utils"
import HtmlTextBox from "@/components/storyblok/common/HtmlTextBox"

interface CategoryDescriptionProps {
  text: string
  contentAnchor: string
}

const CategoryDescription = (props: CategoryDescriptionProps) => {
  const [collapsed, setCollapsed] = useState(true)
  const t = useTranslations("storefront")

  return (
    <>
      <HtmlTextBox
        classname={cn({
          "h-[3.25rem] overflow-hidden sm:h-auto [&>p]:my-0 [&>*]:text-ellipsis [&>*]:line-clamp-2 sm:[&>*]:line-clamp-none":
            collapsed,
        })}
        text={props.text}
      />
      <button
        className={cn("block p-0 text-sm underline sm:hidden", { hidden: !collapsed })}
        onClick={() => setCollapsed(!collapsed)}
      >
        {t("category.more")}
      </button>
      <div className="flex w-full flex-row justify-between">
        <Link
          prefetch={false}
          className={cn("block text-sm underline", { "hidden sm:block": collapsed })}
          href={`#${props.contentAnchor}`}
          target={"_self"}
        >
          {t("category.more")}
        </Link>
        <button
          className={cn("block p-0 text-sm underline sm:hidden", { hidden: collapsed })}
          onClick={() => setCollapsed(!collapsed)}
        >
          {t("category.less")}
        </button>
      </div>
    </>
  )
}

export default CategoryDescription
