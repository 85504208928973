import { cva, type VariantProps } from "class-variance-authority"

import { toClassVariant } from "../helpers/utils"

const marginValues = [
  "auto",
  "0",
  "0.5",
  "1",
  "1.5",
  "2",
  "2.5",
  "3",
  "3.5",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "14",
  "16",
  "20",
  "24",
  "28",
  "32",
  "36",
  "40",
]

const marginDef = cva("block", {
  variants: {
    margin: toClassVariant(marginValues, "m"),
    marginRight: toClassVariant(marginValues, "mr"),
    marginBottom: toClassVariant(marginValues, "mb"),
    marginLeft: toClassVariant(marginValues, "ml"),
    marginTop: toClassVariant(marginValues, "mt"),
    marginX: toClassVariant(marginValues, "mx"),
    marginY: toClassVariant(marginValues, "my"),
    // You can use variant modifiers to target media queries such as responsive breakpoint lg
    marginLg: toClassVariant(marginValues, "lg:m"),
    marginRightLg: toClassVariant(marginValues, "lg:mr"),
    marginBottomLg: toClassVariant(marginValues, "lg:mb"),
    marginLeftLg: toClassVariant(marginValues, "lg:ml"),
    marginTopLg: toClassVariant(marginValues, "lg:mt"),
    marginXLg: toClassVariant(marginValues, "lg:mx"),
    marginYLg: toClassVariant(marginValues, "lg:my"),
  },
})

export { marginDef, marginValues }
export type MarginProps = VariantProps<typeof marginDef>
