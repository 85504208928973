import { sendGTMEvent } from "@next/third-parties/google"

import { ReducedProductData } from "@/lib/storefront/product/product"

export function trackProductClick(product: ReducedProductData, trackingList: string, position: number) {
  /*

//For reference: an original event json payload from legacy app

{
    "event": "productClick",
    "ecommerce": {
    "click": {
      "actionField": {
        "list": "Category Resultslist",
          "action": "click"
      },
      "products": [
        {
          "category": "Programm GW-Mailand",
          "id": "A254411",
          "list": "Category Resultslist",
          "name": "Elektrisch höhenverstellbarer Schreibtisch GW-MAILAND 7909",
          "position": 1
        }
      ]
    }
  },
    "gtm.uniqueEventId": 244
  }*/

  const trackedProductId = product.id.toLowerCase().startsWith("e") ? product.id.toUpperCase() : "A" + product.id

  const productClickData = {
    event: "productClick",
    ecommerce: {
      click: {
        actionField: {
          list: trackingList,
          action: "click",
        },
        products: [
          {
            category: product.category ?? "",
            id: trackedProductId,
            list: trackingList,
            name: product.title,
            position: position,
          },
        ],
      },
    },
  }

  sendGTMEvent(productClickData)
}
