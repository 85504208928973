import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "../helpers/utils"
import { gapDef, itemAlignDef, type GapProps, type ItemAlignProps } from "../props"

const gridVariants = cva("grid", {
  variants: {
    columns: {
      "0": "grid-cols-0",
      "1": "grid-cols-1",
      "2": "grid-cols-2",
      "3": "grid-cols-3",
      "4": "grid-cols-4",
      "5": "grid-cols-5",
      "6": "grid-cols-6",
      "7": "grid-cols-7",
      "8": "grid-cols-8",
      "9": "grid-cols-9",
      "10": "grid-cols-10",
      "11": "grid-cols-11",
      "12": "grid-cols-12",
      none: "grid-cols-none",
    },
    columnsMd: {
      "0": "md:grid-cols-0",
      "1": "md:grid-cols-1",
      "2": "md:grid-cols-2",
      "3": "md:grid-cols-3",
      "4": "md:grid-cols-4",
      "5": "md:grid-cols-5",
      "6": "md:grid-cols-6",
      "7": "md:grid-cols-7",
      "8": "md:grid-cols-8",
      "9": "md:grid-cols-9",
      "10": "md:grid-cols-10",
      "11": "md:grid-cols-11",
      "12": "md:grid-cols-12",
      none: "md:grid-cols-none",
    },
    // You can use variant modifiers to target media queries such as responsive breakpoint lg
    columnsLg: {
      "0": "lg:grid-cols-0",
      "1": "lg:grid-cols-1",
      "2": "lg:grid-cols-2",
      "3": "lg:grid-cols-3",
      "4": "lg:grid-cols-4",
      "5": "lg:grid-cols-5",
      "6": "lg:grid-cols-6",
      "7": "lg:grid-cols-7",
      "8": "lg:grid-cols-8",
      "9": "lg:grid-cols-9",
      "10": "lg:grid-cols-10",
      "11": "lg:grid-cols-11",
      "12": "lg:grid-cols-12",
      none: "lg:grid-cols-none",
    },
    columnsXl: {
      "0": "xl:grid-cols-0",
      "1": "xl:grid-cols-1",
      "2": "xl:grid-cols-2",
      "3": "xl:grid-cols-3",
      "4": "xl:grid-cols-4",
      "5": "xl:grid-cols-5",
      "6": "xl:grid-cols-6",
      "7": "xl:grid-cols-7",
      "8": "xl:grid-cols-8",
      "9": "xl:grid-cols-9",
      "10": "xl:grid-cols-10",
      "11": "xl:grid-cols-11",
      "12": "xl:grid-cols-12",
      none: "xl:grid-cols-none",
    },
    columnsXxl: {
      "0": "2xl:grid-cols-0",
      "1": "2xl:grid-cols-1",
      "2": "2xl:grid-cols-2",
      "3": "2xl:grid-cols-3",
      "4": "2xl:grid-cols-4",
      "5": "2xl:grid-cols-5",
      "6": "2xl:grid-cols-6",
      "7": "2xl:grid-cols-7",
      "8": "2xl:grid-cols-8",
      "9": "2xl:grid-cols-9",
      "10": "2xl:grid-cols-10",
      "11": "2xl:grid-cols-11",
      "12": "2xl:grid-cols-12",
      none: "2xl:grid-cols-none",
    },
  },
})

type GridElement = React.ElementRef<"div">

interface GridProps
  extends VariantProps<typeof gridVariants>,
    GapProps,
    ItemAlignProps,
    React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
}

const Grid = React.forwardRef<GridElement, GridProps>(
  (
    {
      className,
      columns,
      columnsMd,
      columnsLg,
      columnsXl,
      columnsXxl,
      justifyItems,
      alignItems,
      gap,
      gapMd,
      gapLg,
      gapXl,
      gapXxl,
      asChild = false,
      ...props
    },
    forwardedRef
  ) => {
    const Comp = asChild ? Slot : "div"
    const effectiveColumns = columns ?? "1"
    const effectiveColumnsMd = columnsMd ?? "2"
    const effectiveColumnsLg = columnsLg ?? "3"
    const effectiveColumnsXl = columnsXl ?? effectiveColumnsLg
    const effectiveColumnsXxl = columnsXxl ?? effectiveColumnsXl

    const effectiveGap = gap ?? "4"
    const effectiveGapMd = gapMd ?? "6"
    const effectiveGapLg = gapLg ?? "8"
    const effectiveGapXl = gapXl ?? effectiveGapLg
    const effectiveGapXxl = gapXxl ?? effectiveGapXl

    // console.log(effectiveColumns, effectiveColumnsLg, effectiveColumnsXl, effectiveGapXxl)
    // console.log(effectiveGap, effectiveGapLg, effectiveGapXl, effectiveGapXxl)
    return (
      <Comp
        {...props}
        ref={forwardedRef}
        className={cn(
          gridVariants({
            columns: effectiveColumns,
            columnsMd: effectiveColumnsMd,
            columnsLg: effectiveColumnsLg,
            columnsXl: effectiveColumnsXl,
            columnsXxl: effectiveColumnsXxl,
          }),
          itemAlignDef({
            justifyItems,
            alignItems,
          }),
          gapDef({
            gap: effectiveGap,
            gapMd: effectiveGapMd,
            gapLg: effectiveGapLg,
            gapXl: effectiveGapXl,
            gapXxl: effectiveGapXxl,
          }),
          className
        )}
      />
    )
  }
)
Grid.displayName = "Grid"

export { Grid }
export type { GridProps }
