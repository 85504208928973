"use client"

import { addArticleToCurrentBasketAction, determineCrosssellingProductsAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ProductPriceContainerCtnrROV1 } from "@ub/pricing-client"
import { ShoppingCart } from "lucide-react"
import { useTranslations } from "next-intl"

import { logger } from "@/lib/logger"
import { ReducedProductData } from "@/lib/storefront/product/product"
import { Button } from "@/components/ui/components"

export default function ProductCardAddToBasket(props: {
  articleIdToAdd: string
  productData: ReducedProductData | undefined
  prices: ProductPriceContainerCtnrROV1 | undefined
}) {
  const {
    setAddToBasketDialogVisible,
    setProductDataAddedToBasket,
    setBasketData,
    setCrosssellingProductDataAddedToBasket,
    setProductDataAddedToBasketPrices,
    setIsLoading,
  } = useUbStorefrontState()
  const t = useTranslations("storefront")

  async function addToBasket() {
    if (props.articleIdToAdd) {
      addArticleToCurrentBasketAction(props.articleIdToAdd, props.productData?.stepOrderQuantity ?? 1)
        .then((newBasketData) => {
          // console.log(`ProductCardAddToBasket: setBasketData()`)
          // console.dir(newBasketData)
          setBasketData(newBasketData)
        })
        .catch((reason) => {
          logger.error(`ProductCardAddToBasket: Backend problem: ${reason}`)
        })

      const crosssellingProducts = await determineCrosssellingProductsAction(props.articleIdToAdd)
      setCrosssellingProductDataAddedToBasket(crosssellingProducts)
    }
  }

  function addToBasketAndShowDialog() {
    setIsLoading(true)
    addToBasket()
      .then(() => {
        // console.log(`ProductCardAddToBasket: setProductDataAddedToBasket`)
        // console.dir(props.productData)
        setProductDataAddedToBasket(props.productData)
        // console.log(`ProductCardAddToBasket: setProductDataAddedToBasketPrices`)
        // console.dir(props.prices)
        setProductDataAddedToBasketPrices(props.prices)
      })
      .finally(() => {
        setAddToBasketDialogVisible(true)
        setIsLoading(false)
      })
  }

  return (
    <Button
      aria-label={t("product.add_to_basket")}
      size={"productCardIcon"}
      onClick={(e) => {
        e.preventDefault()
        addToBasketAndShowDialog()
      }}
    >
      <ShoppingCart className={"size-4"} />
    </Button>
  )
}
