export const UB_INFO_BAR_COOKIE_NAME = "infobarDeactivated" // must match legacy site

export namespace InfoBarCookieValue {
  export function isInfobarDeactivated(cookieVal: string | undefined): boolean {
    if (!cookieVal) {
      return false
    } else {
      return "1" === cookieVal
    }
  }
}
