"use client"

import { useState } from "react"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { MailIcon, MessageSquareIcon, PhoneCallIcon, XIcon } from "lucide-react"

import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/components"
import UbRichTextCard from "@/components/storyblok/UbRichTextCard"

interface StoryblokServicePhoneProps {
  blok: any
  sbLocale: string
}

export default function StoryblokServicePhone(props: StoryblokServicePhoneProps) {
  const [popUpOpen, setPopUpOpen] = useState(false)

  const closePopup = () => {
    setPopUpOpen(false)
  }

  return (
    <div className="hidden items-center justify-center md:flex">
      <Popover open={popUpOpen} onOpenChange={setPopUpOpen}>
        <PopoverTrigger aria-label={props.blok.menu_text}>
          <div className="flex flex-row text-xs font-bold tracking-tight xl:tracking-normal">
            <PhoneCallIcon className="mr-2 h-[20px] text-black" />
            <p className="mt-0.5 text-nowrap">{props.blok.menu_text}</p>
            <ChevronDownIcon className={"size-5 text-black"} />
          </div>
        </PopoverTrigger>
        <PopoverContent className="mr-52 w-[610px] rounded-none border-black p-6">
          <div className="flex w-full justify-between">
            <h2 className="mb-3.5 text-lg font-bold">{props.blok.headline}</h2>
            <button onClick={closePopup} className="size-5">
              <XIcon className="size-5" />
            </button>
          </div>
          <div className="flex flex-row gap-5 text-sm">
            {props.blok.service_text_1 ? (
              <div className="flex grow flex-col border-r-2 border-gray-300 ">
                <PhoneCallIcon className="size-5"></PhoneCallIcon>
                <UbRichTextCard
                  blok={{ richtext: props.blok.service_text_1, _uid: "", component: "ub_rich_text_card" }}
                  sbLocale={props.sbLocale}
                />
              </div>
            ) : (
              <div className="flex grow flex-col border-r-2 border-gray-300 ">
                <PhoneCallIcon className="size-5"></PhoneCallIcon>
                <a href="#" className="text-sm font-bold text-gray-500">
                  Telefon: 0203 28277-420
                </a>
                <p className="font-bold">Fax: 0203 28277-450</p>
                <p className="text-xs">Mo.-Do. 7:30 - 18:00 Uhr,</p>
                <p className="text-xs">Fr. 7:30 - 17:00 Uhr</p>
              </div>
            )}
            {props.blok.service_text_2 ? (
              <div className="flex grow flex-col border-r-2 border-gray-300 ">
                <MailIcon className="size-5"></MailIcon>
                <UbRichTextCard
                  blok={{ richtext: props.blok.service_text_2, _uid: "", component: "ub_rich_text_card" }}
                  sbLocale={props.sbLocale}
                />
              </div>
            ) : (
              <div className="flex grow flex-col border-r-2 border-gray-300 ">
                <MailIcon className="size-5"></MailIcon>
                <a href="#" className="text-sm font-bold text-gray-500">
                  E-Mail: service@udobaer.de
                </a>
                <a href="#" className="text-sm font-bold text-gray-500">
                  Kontaktformular
                </a>
                <a href="#" className="text-sm font-bold text-gray-500">
                  Newsletter
                </a>
              </div>
            )}

            {props.blok.service_text_3 ? (
              <div className="flex grow flex-col">
                <MessageSquareIcon className="size-5"></MessageSquareIcon>
                <UbRichTextCard
                  blok={{ richtext: props.blok.service_text_3, _uid: "", component: "ub_rich_text_card" }}
                  sbLocale={props.sbLocale}
                />
              </div>
            ) : (
              <div className="flex grow flex-col">
                <MessageSquareIcon className="size-5"></MessageSquareIcon>
                <a href="javascript:userlike.userlikeStartChat()" className="text-sm font-bold text-gray-500">
                  Online - Chat
                </a>
              </div>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
