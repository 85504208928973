"use client"

import React from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { CustomerTypeCookieValue } from "@/lib/customer-type-cookies"
import { Button } from "@/components/ui/components"
import { setCustomerTypeCookie } from "@/app/actions"

const StoryblokCustomerTypeDialog = ({ onClose }: { onClose: () => void }) => {
  const { isB2BCustomer, setIsB2BCustomer } = useUbStorefrontState()
  const t = useTranslations("storefront")

  function switchCustomerType() {
    const newIsB2BCustomer = !isB2BCustomer
    //setIsB2BCustomer(newIsB2BCustomer)
    setCustomerTypeCookie(CustomerTypeCookieValue.getCookieValue(newIsB2BCustomer)).then((r) => {
      onClose()
    })
  }

  return (
    <div onClick={onClose} className={"fixed inset-0 z-50 bg-black/90"}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={"absolute left-1/2 top-1/3  w-11/12 -translate-x-1/2 -translate-y-1/2 pt-4 md:w-auto"}
      >
        <div className={"flex flex-col gap-10 bg-white p-6"}>
          <h2 className={"text-2xl font-bold"}>{t(`customer.switch.header.${isB2BCustomer ? "b2c" : "b2b"}`)}</h2>
          <div className={"flex flex-col justify-between gap-3 md:mx-20"}>
            <p className={"mb-2 text-xl"}>{t(`customer.switch.description.${isB2BCustomer ? "b2c" : "b2b"}`)}</p>

            <Button onClick={onClose} className={"grow text-xl font-bold"} variant="outline">
              {t("common.cancel")}
            </Button>
            <Button onClick={switchCustomerType} className={"grow text-xl font-bold"}>
              {t("common.switch")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryblokCustomerTypeDialog
