import * as React from "react"
import { Slot } from "@radix-ui/react-slot"

import { cn } from "../helpers/utils"
import {
  bgColorDef,
  marginDef,
  paddingDef,
  type BackgroundColorProps,
  type BackgroundImageProps,
  type MarginProps,
  type PaddingProps,
} from "../props"

type ContainerElement = React.ElementRef<"div">

interface ContainerProps
  extends BackgroundColorProps,
    BackgroundImageProps,
    MarginProps,
    PaddingProps,
    React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
}

const Container = React.forwardRef<ContainerElement, ContainerProps>(
  (
    {
      className,
      margin,
      marginRight,
      marginBottom,
      marginLeft,
      marginTop,
      marginX,
      marginY,
      marginLg,
      marginRightLg,
      marginBottomLg,
      marginLeftLg,
      marginTopLg,
      marginXLg,
      marginYLg,
      padding,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingTop,
      paddingX,
      paddingY,
      paddingLg,
      paddingRightLg,
      paddingBottomLg,
      paddingLeftLg,
      paddingTopLg,
      paddingXLg,
      paddingYLg,
      bgColor,
      bgImage,
      asChild = false,
      ...props
    },
    forwardedRef
  ) => {
    const Comp = asChild ? Slot : "div"

    return (
      <Comp
        {...props}
        ref={forwardedRef}
        style={bgImage && bgImage.filename ? { backgroundImage: "url(" + bgImage.filename + ")" } : {}}
        className={cn(
          marginDef({
            margin,
            marginRight,
            marginBottom,
            marginLeft,
            marginTop,
            marginX,
            marginY,
            marginLg,
            marginRightLg,
            marginBottomLg,
            marginLeftLg,
            marginTopLg,
            marginXLg,
            marginYLg,
          }),
          paddingDef({
            padding,
            paddingRight,
            paddingBottom,
            paddingLeft,
            paddingTop,
            paddingX,
            paddingY,
            paddingLg,
            paddingRightLg,
            paddingBottomLg,
            paddingLeftLg,
            paddingTopLg,
            paddingXLg,
            paddingYLg,
          }),
          bgColorDef({ bgColor }),
          "bg-cover",
          className
        )}
      />
    )
  }
)
Container.displayName = "Container"

export { Container }
export type { ContainerProps }
