import { SortOrder } from "@/lib/factfinder/factfinder-backend"

export const DEFAULT_SEARCH_RESULT_PAGE_SIZES = [24, 48, 96]
export const DEFAULT_SEARCH_RESULT_VISIBLE_GROUPS = 5
export const DEFAULT_SEARCH_RESULT_GROUP_SIZE = 3
export const DEFAULT_SEARCH_RESULT_SORT_ORDER: SortOrder[] = [
  "relevance",
  "name_asc",
  "name_desc",
  "newproducts",
  "price_asc",
  "price_desc",
  "fastdelivery",
  "topsellers",
]

export const SEARCH_PARAMS_PAGE = "p"
export const SEARCH_PARAMS_PAGE_SIZE = "ps"
export const SEARCH_PARAMS_SORT_ORDER = "s"

export const SEARCH_PARAMS_FF_SEARCH_PARAMS = "sp"

export const SEARCH_PARAMS_MIN_PRICE = "minprice"
export const SEARCH_PARAMS_MAX_PRICE = "maxprice"
