"use client"

import { useEffect, useState } from "react"

import { ReducedProductData } from "@/lib/storefront/product/product"
import { trackProductClick } from "@/lib/tracking/tracking-helper"

/*The purpose of this */
export default function ProductClickTrackingClient(props: {
  trackingList?: string
  productData: ReducedProductData
  position: number
  trackingMarker: string
}) {
  const [tracked, setTracked] = useState(false)

  useEffect(() => {
    function trackingHandler() {
      console.info("Tracking productClick")
      trackProductClick(props.productData, props.trackingList ?? "", props.position)
      setTracked(true)
    }

    //console.log("Running tracking event listener attach")
    if (props.trackingList !== undefined) {
      if (!tracked) {
        const element = document.querySelector('[data-tracking-marker="' + props.trackingMarker + '"]')
        if (element) {
          // console.info("Tracking element found")
          element.addEventListener("click", trackingHandler)
        } else {
          // console.info("Tracking element not found -> not connecting tracking event")
        }
      } else {
        //console.info("already tracked")
      }
    } else {
      //console.info("trackingList not found -> not connecting tracking event")
    }
    return () => {
      const element = document.querySelector('[data-tracking-marker="' + props.trackingMarker + '"]')
      if (element) {
        element.removeEventListener("click", trackingHandler)
      }
    }
  }, [props.position, props.productData, props.trackingList, props.trackingMarker, tracked])

  return (
    <>
      <span className={"hidden"}>{props.trackingMarker}</span>
    </>
  )
}
