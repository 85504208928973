import { cva, type VariantProps } from "class-variance-authority"

import { toClassVariant } from "../helpers/utils"

const gapValues = ["0", "0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "5", "6", "7", "8", "9", "10", "11", "12"]

const gapDef = cva("", {
  variants: {
    gap: toClassVariant(gapValues, "gap"),
    gapMd: toClassVariant(gapValues, "md:gap"),
    gapLg: toClassVariant(gapValues, "lg:gap"),
    gapXl: toClassVariant(gapValues, "xl:gap"),
    gapXxl: toClassVariant(gapValues, "2xl:gap"),
  },
})

const itemAlignValues = ["start", "end", "center", "stretch"]

const itemAlignDef = cva("", {
  variants: {
    justifyItems: toClassVariant(itemAlignValues, "justify-items"),
    alignItems: toClassVariant(itemAlignValues, "items"),
  },
})

export { gapDef, gapValues, itemAlignDef, itemAlignValues }
export type GapProps = VariantProps<typeof gapDef>
export type ItemAlignProps = VariantProps<typeof itemAlignDef>
