const Locales = {
  // name of the default locale configured in Storyblok
  defaultSbLocale: "",
  sbLocaleDe: "",

  // This is used by Storyblok in field values and other cases. Why???
  defaultSbLocaleSpecialCases: "default",

  ubLocales: ["de-at", "de-ch", "de", "fr-ch", "nl", "nl-nr", "fr", "fr-fr"],

  //  udobaer string locale(country - language) of the default Storyblok locale
  defaultUbLocaleForDefaultSbLocale: "de-de",

  sbLocaleToIsoLocale: {
    "de-at": "de-AT",
    "de-ch": "de-CH",
    "fr-ch": "fr-CH",
    nl: "nl-NL",
    fr: "fr-FR",
    "": "de-DE",
  },

  sbLocaleToUbLocale: {
    "de-at": "at-de",
    "de-ch": "ch-de",
    "fr-ch": "ch-fr",
    nl: "nl-nl",
    fr: "fr-fr",
    "": "de-de",
  },

  sbLocaleToIntershopLocale: {
    // Intershop Sites are configured to only use these languages (no country specifics):
    "de-at": "de_DE",
    "de-ch": "de_DE",
    nl: "nl_NL",
    fr: "fr_FR",
    "fr-ch": "fr_FR",
    "": "de_DE",
  },

  // this is the semantic of "default locale" in StoryBlok when the locale is empty ("")
  shortLocaleForDefaultSbLocale: "de",

  // see also url-scheme.js
  sbLocaleToDomain: {
    "de-at": "udobaer.at",
    "de-ch": "udobaer.ch",
    "fr-ch": "udobaer.ch",
    nl: "udobaer.nl",
    fr: "udobaer.fr",
    "": "udobaer.de",
  },
}

module.exports = {
  Locales,
}
