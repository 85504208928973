"use client"

import React from "react"
import Image from "next/image"

const ImageWithFallback = ({ src, alt, ...restProps }) => {
  return (
    <Image
      src={src}
      alt={alt}
      {...restProps}
      onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.id = "/img/not_available.png"
        event.currentTarget.srcset = "/img/not_available.png"
      }}
    />
  )
}

export default ImageWithFallback
