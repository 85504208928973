"use client"

import { LogOutIcon } from "lucide-react"
import { useTranslations } from "next-intl"

export default function StoryblokLogoutButton() {
  const t = useTranslations("storefront")
  const handleSubmit = async () => {
    try {
      await fetch(
        process.env.NEXT_PUBLIC_BASE_PATH ? process.env.NEXT_PUBLIC_BASE_PATH + "/api/auth/logout" : "/api/auth/logout",
        {
          method: "POST",
        }
      )

      window.location.replace("/")
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
    <button
      onClick={handleSubmit}
      className="group mt-2 flex w-full gap-x-3 rounded-md py-2 leading-6 hover:text-primary-hover"
    >
      <LogOutIcon className="size-6 shrink-0 text-primary group-hover:text-primary-hover" aria-hidden="true" />
      {t("account.nav.sign-out")}
    </button>
  )
}
