import DOMPurify from "isomorphic-dompurify"

import { cn } from "@/lib/utils"

interface HtmlTextBoxProps {
  text: string
  classname?: string
}

const HtmlTextBox = (props: Readonly<HtmlTextBoxProps>) => {
  const domains = ["youtube-nocookie.com", "youtube.com"]

  DOMPurify.addHook("uponSanitizeElement", (currentNode, data, _config) => {
    if (data.tagName === "iframe") {
      if (domains.some((domain) => currentNode.getAttribute("src")?.includes(domain))) {
        currentNode.setAttribute("sandbox", "allow-scripts allow-same-origin allow-presentation allow-popups")
      }
    }
  })

  return (
    <div
      className="prose prose-sm relative max-w-full"
      style={{
        ["--tw-prose-body" as string]: "inherit",
        ["--tw-prose-headings" as string]: "inherit",
        ["--tw-prose-lead" as string]: "inherit",
        ["--tw-prose-links" as string]: "inherit",
        ["--tw-prose-bold" as string]: "inherit",
        ["--tw-prose-counters" as string]: "inherit",
        ["--tw-prose-bullets" as string]: "inherit",
        ["--tw-prose-hr" as string]: "inherit",
        ["--tw-prose-quotes" as string]: "inherit",
        ["--tw-prose-quote-borders" as string]: "inherit",
        ["--tw-prose-captions" as string]: "inherit",
        ["--tw-prose-kbd" as string]: "inherit",
        ["--tw-prose-kbd-shadows" as string]: "inherit",
        ["--tw-prose-code" as string]: "inherit",
        ["--tw-prose-pre-code" as string]: "inherit",
        ["--tw-prose-pre-bg" as string]: "inherit",
        ["--tw-prose-th-borders" as string]: "inherit",
        ["--tw-prose-td-borders" as string]: "inherit",
        ["--tw-prose-invert-body" as string]: "inherit",
        ["--tw-prose-invert-headings" as string]: "inherit",
        ["--tw-prose-invert-lead" as string]: "inherit",
        ["--tw-prose-invert-links" as string]: "inherit",
        ["--tw-prose-invert-bold" as string]: "inherit",
        ["--tw-prose-invert-counters" as string]: "inherit",
        ["--tw-prose-invert-bullets" as string]: "inherit",
        ["--tw-prose-invert-hr" as string]: "inherit",
        ["--tw-prose-invert-quotes" as string]: "inherit",
        ["--tw-prose-invert-quote-borders" as string]: "inherit",
        ["--tw-prose-invert-captions" as string]: "inherit",
        ["--tw-prose-invert-kbd" as string]: "inherit",
        ["--tw-prose-invert-kbd-shadows" as string]: "inherit",
        ["--tw-prose-invert-code" as string]: "inherit",
        ["--tw-prose-invert-pre-code" as string]: "inherit",
        ["--tw-prose-invert-pre-bg" as string]: "inherit",
        ["--tw-prose-invert-th-borders" as string]: "inherit",
        ["--tw-prose-invert-td-borders" as string]: "inherit",
      }}
    >
      <div
        className={cn("", props.classname)}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.text, {
            ADD_TAGS: ["iframe"],
            ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling", "sandbox"],
          }),
        }}
      />
    </div>
  )
}

export default HtmlTextBox
