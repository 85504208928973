"use client"

import React, { useEffect, useState } from "react"
import Cookies from "js-cookie"
import { XIcon } from "lucide-react"

import { InfoBarCookieValue, UB_INFO_BAR_COOKIE_NAME } from "@/lib/info-bar-cookies"
import { cn } from "@/lib/utils"

export default function InfoBarClient(props) {
  const [infobarDeactivated, setInfobarDeactivated] = useState<boolean>(true)
  const [transitionToHiding, setTransitionToHiding] = useState(false)

  const hideInfoBar = () => {
    setTransitionToHiding(true)
    setTimeout(() => {
      setInfobarDeactivated(true)
      Cookies.set(UB_INFO_BAR_COOKIE_NAME, "1")
    }, 150)
  }

  useEffect(() => {
    const exitPopupCookieValue = Cookies.get(UB_INFO_BAR_COOKIE_NAME)
    const ecShownFromCookie = InfoBarCookieValue.isInfobarDeactivated(exitPopupCookieValue)
    setInfobarDeactivated(ecShownFromCookie)
  }, [])

  return (
    <>
      {!infobarDeactivated && (
        <div
          className={cn(
            "fixed inset-x-0 bottom-0 z-[200] flex h-12 w-screen overflow-hidden bg-amber-400 px-5 transition-all",
            {
              "h-0": transitionToHiding,
            }
          )}
        >
          <div className="flex grow flex-col items-center justify-center">{props.children}</div>
          <XIcon onClick={hideInfoBar} className="h-full cursor-pointer"></XIcon>
        </div>
      )}
    </>
  )
}
