"use client"

import { useState } from "react"
import * as React from "react"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { useTranslations } from "next-intl"

import { FacetData, FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { Category } from "@/lib/storefront/category/category"
import { cn } from "@/lib/utils"

import FacetElement from "./FacetElement"

export interface FacetProps {
  facetData: FacetData
  query?: string
  baseCategory?: Category
  brandSlug?: string
  filterFunction?: (facetElement: FacetElementData) => boolean
}

const UNSELECTED_ELEMENT_COUNT_TO_SHOW = 10

export default function Facet(props: FacetProps) {
  const t = useTranslations("storefront")

  const [showAll, setShowAll] = useState(false)
  let showUnselectedOnlyFirst: number = UNSELECTED_ELEMENT_COUNT_TO_SHOW
  if (props.filterFunction) {
    // Show all elements if user used a filter
    showUnselectedOnlyFirst = Infinity
  }
  const hiddenCount = (props.facetData?.facetElements?.length || 0) - showUnselectedOnlyFirst
  if (hiddenCount <= 1) {
    showUnselectedOnlyFirst = Infinity
  }

  const isShipmentDurationFacet = props.facetData?.fieldName === "readyForShipmentMin"
  const sortFunction = isShipmentDurationFacet ? sortByMinShipmentDays : noSort

  return (
    <div className="mt-3 flex flex-col">
      {/* selected elemets are always shown */}
      <div className="flex flex-col gap-2">
        {props.facetData?.selectedFacetElements &&
          props.facetData.selectedFacetElements
            .filter((elemData: FacetElementData) => !props.filterFunction || props.filterFunction(elemData))
            .map((elemData: FacetElementData, index) => (
              <FacetElement
                key={index}
                query={props.query}
                baseCategory={props.baseCategory}
                brandSlug={props.brandSlug}
                facetElementData={elemData}
              />
            ))}
      </div>

      {/* unselected elements: only show first showUnselectedOnlyFirst */}
      <div className="flex flex-col gap-2">
        {props.facetData?.facetElements &&
          props.facetData.facetElements
            .filter((elemData: FacetElementData) => !props.filterFunction || props.filterFunction(elemData))
            .sort(sortFunction)
            .map((elemData: FacetElementData, index) => (
              <div key={index} className={index < showUnselectedOnlyFirst || showAll ? "block" : "hidden"}>
                <FacetElement
                  key={index}
                  query={props.query}
                  baseCategory={props.baseCategory}
                  brandSlug={props.brandSlug}
                  facetElementData={elemData}
                />
              </div>
            ))}
        {props.facetData?.facetElements && props.facetData.facetElements.length > showUnselectedOnlyFirst && (
          <button
            className="my-2 flex w-full justify-between rounded-sm border border-gray-200 bg-white p-2 text-xs font-bold text-gray-500"
            onClick={() => setShowAll(!showAll)}
          >
            <p>{showAll ? t("common.show_less") : t("common.show_further_count", { count: hiddenCount })}</p>
            <ChevronDownIcon
              className={cn("", {
                "rotate-180": showAll,
              })}
              aria-hidden
            />
          </button>
        )}
      </div>
    </div>
  )
}

function sortByHitCount(a: FacetElementData, b: FacetElementData): number {
  return b.hitcount - a.hitcount
}

function sortByMinShipmentDays(a: FacetElementData, b: FacetElementData): number {
  if (a.minShipmentDays === undefined) {
    return 1
  }
  if (b.minShipmentDays === undefined) {
    return -1
  }
  return a.minShipmentDays - b.minShipmentDays
}

function noSort(a: FacetElementData, b: FacetElementData): number {
  return 1
}
