export const UB_CUSTOMER_TYPE_COOKIE_NAME = "ubCustomerType" // must match legacy site

export enum CustomerTypeCookieValue {
  B2B = "b2b", // must match legacy site
  B2C = "b2c",
}

export namespace CustomerTypeCookieValue {
  export function isB2bCustomer(cookieVal: CustomerTypeCookieValue | undefined): boolean {
    if (!cookieVal) return true
    return CustomerTypeCookieValue.B2B === cookieVal
  }

  export function getCookieValue(isB2bCustomerState: boolean) {
    return isB2bCustomerState ? CustomerTypeCookieValue.B2B : CustomerTypeCookieValue.B2C
  }

  export function fromString(value: string | undefined): CustomerTypeCookieValue {
    if (!value) {
      return CustomerTypeCookieValue.B2B
    }
    for (const key in CustomerTypeCookieValue) {
      if (value == CustomerTypeCookieValue[key]) {
        return value as CustomerTypeCookieValue
      }
    }
    return CustomerTypeCookieValue.B2B
  }
}
