import { getCountry, getCurrency, getIsoLocale } from "./link-utils/get-sb-locale"

export async function loadInParallel<T1, T2>(f1: Promise<T1>, f2: Promise<T2>): Promise<[T1, T2]> {
  const promises = [f1, f2]

  const parallelResult = await Promise.allSettled(promises)
  let result1: any
  if (parallelResult[0].status === "fulfilled") {
    result1 = parallelResult[0].value
  }
  let result2: any
  if (parallelResult[1].status === "fulfilled") {
    result2 = parallelResult[1].value
  }
  return [result1, result2]
}

export function formatPrice(price?: number, currency?: string): string {
  if (price !== undefined && currency) {
    return new Intl.NumberFormat(getIsoLocale(), {
      style: "currency",
      currency: currency,
    }).format(price)
  }
  return ""
}

export function formatAndReplacePrices(stringWithUnformattedPrices: string): string {
  if (!stringWithUnformattedPrices) {
    return ""
  }

  const currency = getCurrency()
  const formatted = stringWithUnformattedPrices.replace(/\b\d+\.\d{2}\b/g, (match) => {
    const price = parseFloat(match)
    return formatPrice(price, currency)
  })

  return formatted
}

export function getLastElement(subject: string, separator: string): string {
  return subject.split(separator).slice(-1)[0]
}

export function showContentCountryDependent(countriesToExclude: any) {
  const currentCountry = getCountry()
  const currentCountryExcluded: boolean = countriesToExclude?.includes(currentCountry) || false
  return !currentCountryExcluded
}

export function truncateString(str: string, length: number = 100): string {
  return str.length > length ? str.slice(0, length) + "..." : str
}

export async function readStreamBody(stream: ReadableStream) {
  const reader = stream.getReader()
  const chunks: Uint8Array[] = []
  let result

  while (!(result = await reader.read()).done) {
    chunks.push(result.value)
  }

  return new TextDecoder().decode(Buffer.concat(chunks))
}
