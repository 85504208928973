function getSizeFromImagePath(sbImagepath: string): { width: number; height: number } {
  const regex = /\/(\d+)x(\d+)\//
  const match = sbImagepath.match(regex)

  if (!match) {
    return {
      width: 0,
      height: 0,
    }
  }
  const width = match[1]
  const height = match[2]
  return {
    width: Number(width),
    height: Number(height),
  }
}

export function getResizedSbImagepath(sbImagepath: string | undefined): string {
  if (!sbImagepath) {
    return ""
  }
  const size = getSizeFromImagePath(sbImagepath)

  // maxPlausibleWidth 800 because we assume at least 2 columns of card in max 1600 screen.
  const maxPlausibleWidth = 800
  // maxPlausibleHeight because max supported screensize is 1920x1080
  const maxPlausibleHeight = 1000

  // reduce size of large images
  // reduce image size. See https://www.storyblok.com/docs/image-service#resizing
  if (size.width > maxPlausibleWidth) {
    return `${sbImagepath}/m/${maxPlausibleWidth}x0`
  }
  if (size.height > maxPlausibleHeight) {
    return `${sbImagepath}/m/0x${maxPlausibleHeight}`
  }
  // leave small images
  return sbImagepath
}
